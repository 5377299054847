import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type Maybe<T> = T | undefined;
export type InputMaybe<T> = T | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = {
  [_ in K]?: never;
};
export type Incremental<T> =
  | T
  | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BooleanType: { input: any; output: any };
  CustomData: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  FloatType: { input: any; output: any };
  IntType: { input: any; output: any };
  ItemId: { input: any; output: any };
  JsonField: { input: any; output: any };
  MetaTagAttributes: { input: any; output: any };
  UploadId: { input: any; output: any };
};

/** Record of type Blog Page (blog_page) */
export type BlogPageRecord = RecordInterface & {
  __typename?: 'BlogPageRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  seo?: Maybe<SeoField>;
};

/** Record of type Blog Page (blog_page) */
export type BlogPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type BlogpostCtaModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<BlogpostCtaModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<BlogpostCtaModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  postCategory?: InputMaybe<StringFilter>;
};

export enum BlogpostCtaModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PostCategoryAsc = 'postCategory_ASC',
  PostCategoryDesc = 'postCategory_DESC'
}

/** Record of type Blogpost CTA (blogpost_cta) */
export type BlogpostCtaRecord = RecordInterface & {
  __typename?: 'BlogpostCtaRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content: Array<ContentBlockRecord>;
  id: Scalars['ItemId']['output'];
  postCategory?: Maybe<Scalars['String']['output']>;
};

/** Record of type Blogpost CTA (blogpost_cta) */
export type BlogpostCtaRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Boolean fields */
export type BooleanFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Record of type Case Studies Page (case_studies_page) */
export type CaseStudiesPageRecord = RecordInterface & {
  __typename?: 'CaseStudiesPageRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  seo?: Maybe<SeoField>;
  subtitle?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Record of type Case Studies Page (case_studies_page) */
export type CaseStudiesPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Case Studies Page (case_studies_page) */
export type CaseStudiesPageRecordSubtitleArgs = {
  markdown?: InputMaybe<Scalars['Boolean']['input']>;
};

export type CaseStudyModelContentBlocksField = ImageRecord | QuoteRecord;

export type CaseStudyModelContentField = {
  __typename?: 'CaseStudyModelContentField';
  blocks: Array<CaseStudyModelContentBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type CaseStudyModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<CaseStudyModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<CaseStudyModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  companyLogo?: InputMaybe<FileFilter>;
  companyPosition?: InputMaybe<StringFilter>;
  companyUrl?: InputMaybe<StringFilter>;
  content?: InputMaybe<StructuredTextFilter>;
  date?: InputMaybe<DateFilter>;
  id?: InputMaybe<ItemIdFilter>;
  position?: InputMaybe<PositionFilter>;
  project?: InputMaybe<StringFilter>;
  review?: InputMaybe<StringFilter>;
  reviewerAvatar?: InputMaybe<FileFilter>;
  reviewerName?: InputMaybe<StringFilter>;
  seo?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<StringFilter>;
  solution?: InputMaybe<StringFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum CaseStudyModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CompanyPositionAsc = 'companyPosition_ASC',
  CompanyPositionDesc = 'companyPosition_DESC',
  CompanyUrlAsc = 'companyUrl_ASC',
  CompanyUrlDesc = 'companyUrl_DESC',
  DateAsc = 'date_ASC',
  DateDesc = 'date_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  ProjectAsc = 'project_ASC',
  ProjectDesc = 'project_DESC',
  ReviewAsc = 'review_ASC',
  ReviewDesc = 'review_DESC',
  ReviewerNameAsc = 'reviewerName_ASC',
  ReviewerNameDesc = 'reviewerName_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  SolutionAsc = 'solution_ASC',
  SolutionDesc = 'solution_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type Case Study (case_study) */
export type CaseStudyRecord = RecordInterface & {
  __typename?: 'CaseStudyRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  companyLogo?: Maybe<FileField>;
  companyPosition?: Maybe<Scalars['String']['output']>;
  companyUrl?: Maybe<Scalars['String']['output']>;
  content?: Maybe<CaseStudyModelContentField>;
  date: Scalars['Date']['output'];
  id: Scalars['ItemId']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
  project: Scalars['String']['output'];
  review: Scalars['String']['output'];
  reviewerAvatar?: Maybe<FileField>;
  reviewerName?: Maybe<Scalars['String']['output']>;
  seo?: Maybe<SeoField>;
  slug?: Maybe<Scalars['String']['output']>;
  solution: Scalars['String']['output'];
  title: Scalars['String']['output'];
};

/** Record of type Case Study (case_study) */
export type CaseStudyRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type CollectionMetadata = {
  __typename?: 'CollectionMetadata';
  count: Scalars['IntType']['output'];
};

export enum ColorBucketType {
  Black = 'black',
  Blue = 'blue',
  Brown = 'brown',
  Cyan = 'cyan',
  Green = 'green',
  Grey = 'grey',
  Orange = 'orange',
  Pink = 'pink',
  Purple = 'purple',
  Red = 'red',
  White = 'white',
  Yellow = 'yellow'
}

export type ColorField = {
  __typename?: 'ColorField';
  alpha: Scalars['IntType']['output'];
  blue: Scalars['IntType']['output'];
  cssRgb: Scalars['String']['output'];
  green: Scalars['IntType']['output'];
  hex: Scalars['String']['output'];
  red: Scalars['IntType']['output'];
};

/** Record of type Company Page (company_page) */
export type CompanyPageRecord = RecordInterface & {
  __typename?: 'CompanyPageRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  seo?: Maybe<SeoField>;
};

/** Record of type Company Page (company_page) */
export type CompanyPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ContactFormModelContentField = {
  __typename?: 'ContactFormModelContentField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type Contact Form (contact_form) */
export type ContactFormRecord = RecordInterface & {
  __typename?: 'ContactFormRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content?: Maybe<ContactFormModelContentField>;
  id: Scalars['ItemId']['output'];
  redirectTo?: Maybe<ContentPageRecord>;
};

/** Block of type Contact Form (contact_form) */
export type ContactFormRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ContentBlockModelContentBlocksField =
  | CtaButtonRecord
  | GridRecord
  | LogosGroupRecord
  | ThreeByThreeTableRecord
  | TwoByTwoTableRecord;

export type ContentBlockModelContentField = {
  __typename?: 'ContentBlockModelContentField';
  blocks: Array<ContentBlockModelContentBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

export type ContentBlockModelQuoteBoxField = {
  __typename?: 'ContentBlockModelQuoteBoxField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type Content Block (content_block) */
export type ContentBlockRecord = RecordInterface & {
  __typename?: 'ContentBlockRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content?: Maybe<ContentBlockModelContentField>;
  id: Scalars['ItemId']['output'];
  imageMargin?: Maybe<Scalars['IntType']['output']>;
  imageSize?: Maybe<Scalars['IntType']['output']>;
  leftImage?: Maybe<FileField>;
  quoteBox?: Maybe<ContentBlockModelQuoteBoxField>;
  rightImage?: Maybe<FileField>;
};

/** Block of type Content Block (content_block) */
export type ContentBlockRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ContentPageModelContentField = ContactFormRecord | ContentBlockRecord | HeaderRecord;

export type ContentPageModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<ContentPageModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<ContentPageModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  id?: InputMaybe<ItemIdFilter>;
  seo?: InputMaybe<SeoFilter>;
  slug?: InputMaybe<SlugFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum ContentPageModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type Content Page (content_page) */
export type ContentPageRecord = RecordInterface & {
  __typename?: 'ContentPageRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content: Array<ContentPageModelContentField>;
  id: Scalars['ItemId']['output'];
  seo?: Maybe<SeoField>;
  slug?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Record of type Content Page (content_page) */
export type ContentPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by creation datetime */
export type CreatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Block of type CTA Button (cta_button) */
export type CtaButtonRecord = RecordInterface & {
  __typename?: 'CtaButtonRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  buttonLink?: Maybe<Scalars['String']['output']>;
  buttonText?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  newTab?: Maybe<Scalars['BooleanType']['output']>;
  trackingId?: Maybe<Scalars['String']['output']>;
};

/** Block of type CTA Button (cta_button) */
export type CtaButtonRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter Date fields */
export type DateFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['Date']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['Date']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['Date']['input']>;
};

export enum FaviconType {
  AppleTouchIcon = 'appleTouchIcon',
  Icon = 'icon',
  MsApplication = 'msApplication'
}

export type FileField = FileFieldInterface & {
  __typename?: 'FileField';
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};

export type FileFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};

export type FileFieldCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};

export type FileFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

export type FileFieldInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** The DatoCMS URL where you can edit this entity. To use this field, you need to set a X-Base-Editing-Url header in the request */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  alt?: Maybe<Scalars['String']['output']>;
  author?: Maybe<Scalars['String']['output']>;
  basename: Scalars['String']['output'];
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  colors: Array<ColorField>;
  copyright?: Maybe<Scalars['String']['output']>;
  customData: Scalars['CustomData']['output'];
  exifInfo: Scalars['CustomData']['output'];
  filename: Scalars['String']['output'];
  focalPoint?: Maybe<FocalPoint>;
  format: Scalars['String']['output'];
  height?: Maybe<Scalars['IntType']['output']>;
  id: Scalars['UploadId']['output'];
  md5: Scalars['String']['output'];
  mimeType: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  responsiveImage?: Maybe<ResponsiveImage>;
  size: Scalars['IntType']['output'];
  smartTags: Array<Scalars['String']['output']>;
  tags: Array<Scalars['String']['output']>;
  thumbhash?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  url: Scalars['String']['output'];
  video?: Maybe<UploadVideoField>;
  width?: Maybe<Scalars['IntType']['output']>;
};

export type FileFieldInterfaceAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};

export type FileFieldInterfaceCustomDataArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceFocalPointArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceResponsiveImageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  imgixParams?: InputMaybe<ImgixParams>;
  locale?: InputMaybe<SiteLocale>;
  sizes?: InputMaybe<Scalars['String']['input']>;
};

export type FileFieldInterfaceTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type FileFieldInterfaceUrlArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
};

/** Specifies how to filter Single-file/image fields */
export type FileFilter = {
  /** Search for records with an exact match. The specified value must be an Upload ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that have one of the specified uploads */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude records with an exact match. The specified value must be an Upload ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Filter records that do not have one of the specified uploads */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

export type GlobalSeoField = {
  __typename?: 'GlobalSeoField';
  facebookPageUrl?: Maybe<Scalars['String']['output']>;
  fallbackSeo?: Maybe<SeoField>;
  siteName?: Maybe<Scalars['String']['output']>;
  titleSuffix?: Maybe<Scalars['String']['output']>;
  twitterAccount?: Maybe<Scalars['String']['output']>;
};

export type GridItemModelContentBlocksField = CtaButtonRecord | ImageRecord;

export type GridItemModelContentField = {
  __typename?: 'GridItemModelContentField';
  blocks: Array<GridItemModelContentBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type Grid Item (grid_item) */
export type GridItemRecord = RecordInterface & {
  __typename?: 'GridItemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  caseStudy?: Maybe<CaseStudyRecord>;
  content?: Maybe<GridItemModelContentField>;
  contentImage?: Maybe<FileField>;
  contentMarginBottom?: Maybe<Scalars['IntType']['output']>;
  contentMarginLeft?: Maybe<Scalars['IntType']['output']>;
  contentMarginRight?: Maybe<Scalars['IntType']['output']>;
  contentMarginTop?: Maybe<Scalars['IntType']['output']>;
  hasBorder?: Maybe<Scalars['BooleanType']['output']>;
  hasBorderShadow?: Maybe<Scalars['BooleanType']['output']>;
  headingFormatting?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  imageHeight?: Maybe<Scalars['IntType']['output']>;
  imageMarginBottom?: Maybe<Scalars['IntType']['output']>;
  imageMarginLeft?: Maybe<Scalars['IntType']['output']>;
  imageMarginRight?: Maybe<Scalars['IntType']['output']>;
  imageMarginTop?: Maybe<Scalars['IntType']['output']>;
  lg?: Maybe<Scalars['IntType']['output']>;
  md?: Maybe<Scalars['IntType']['output']>;
  paragraphFormatting?: Maybe<Scalars['String']['output']>;
  sm?: Maybe<Scalars['IntType']['output']>;
  testimonial?: Maybe<TestimonialRecord>;
  xl?: Maybe<Scalars['IntType']['output']>;
  xs?: Maybe<Scalars['IntType']['output']>;
};

/** Block of type Grid Item (grid_item) */
export type GridItemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Grid (grid) */
export type GridRecord = RecordInterface & {
  __typename?: 'GridRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  gridContent: Array<GridItemRecord>;
  horizontalSpacing?: Maybe<Scalars['FloatType']['output']>;
  id: Scalars['ItemId']['output'];
  verticalSpacing?: Maybe<Scalars['FloatType']['output']>;
};

/** Block of type Grid (grid) */
export type GridRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type HeaderModelContentBlocksField = CtaButtonRecord | GridRecord;

export type HeaderModelContentField = {
  __typename?: 'HeaderModelContentField';
  blocks: Array<HeaderModelContentBlocksField>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type Header (header) */
export type HeaderRecord = RecordInterface & {
  __typename?: 'HeaderRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content?: Maybe<HeaderModelContentField>;
  id: Scalars['ItemId']['output'];
  image?: Maybe<FileField>;
};

/** Block of type Header (header) */
export type HeaderRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Image (image) */
export type ImageRecord = RecordInterface & {
  __typename?: 'ImageRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image?: Maybe<FileField>;
};

/** Block of type Image (image) */
export type ImageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type ImgixParams = {
  /**
   * Aspect Ratio
   *
   * Specifies an aspect ratio to maintain when resizing and cropping the image
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/ar)
   */
  ar?: InputMaybe<Scalars['String']['input']>;
  /**
   * Automatic
   *
   * Applies automatic enhancements to images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/auto)
   */
  auto?: InputMaybe<Array<ImgixParamsAuto>>;
  /**
   * Background Color
   *
   * Colors the background of padded and partially-transparent images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/bg)
   */
  bg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal
   *
   * Removes background from image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemove?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-remove failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-remove)
   */
  bgRemoveFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement
   *
   * Replaces background from image using a string based prompt.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplace?: InputMaybe<Scalars['String']['input']>;
  /**
   * Background Removal Fallback
   *
   * Overrides default fallback behavior for bg-replace failures.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace)
   */
  bgReplaceFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Background Replacement Negative Prompt
   *
   * Provides a negative text suggestion for background replacement.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/background/bg-replace-neg-prompt)
   */
  bgReplaceNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend
   *
   * Specifies the location of the blend image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend)
   */
  blend?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Align
   *
   * Changes the blend alignment relative to the parent image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-align)
   */
  blendAlign?: InputMaybe<Array<ImgixParamsBlendAlign>>;
  /**
   * Blend Alpha
   *
   * Changes the alpha of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-alpha)
   */
  blendAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Color
   *
   * Specifies a color to use when applying the blend.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-color)
   */
  blendColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Blend Crop
   *
   * Specifies the type of crop for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-crop)
   */
  blendCrop?: InputMaybe<Array<ImgixParamsBlendCrop>>;
  /**
   * Blend Fit
   *
   * Specifies the fit mode for blend images.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-fit)
   */
  blendFit?: InputMaybe<ImgixParamsBlendFit>;
  /**
   * Blend Height
   *
   * Adjusts the height of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-h)
   */
  blendH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend Mode
   *
   * Sets the blend mode for a blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-mode)
   */
  blendMode?: InputMaybe<ImgixParamsBlendMode>;
  /**
   * Blend Padding
   *
   * Applies padding to the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-pad)
   */
  blendPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Size
   *
   * Adjusts the size of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-size)
   */
  blendSize?: InputMaybe<ImgixParamsBlendSize>;
  /**
   * Blend Width
   *
   * Adjusts the width of the blend image.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-w)
   */
  blendW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Blend X Position
   *
   * Adjusts the x-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-x)
   */
  blendX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Blend Y Position
   *
   * Adjusts the y-offset of the blend image relative to its parent.
   *
   * Depends on: `blend`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/blending/blend-y)
   */
  blendY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Gaussian Blur
   *
   * Applies a gaussian blur to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/blur)
   */
  blur?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Size & Color
   *
   * Applies a border to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border)
   */
  border?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Bottom
   *
   * Sets bottom border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-bottom)
   */
  borderBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Left
   *
   * Sets left border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-left)
   */
  borderLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Outer Border Radius
   *
   * Sets the outer radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius)
   */
  borderRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Inner Border Radius
   *
   * Sets the inner radius of the image's border in pixels.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-radius-inner)
   */
  borderRadiusInner?: InputMaybe<Scalars['String']['input']>;
  /**
   * Border Right
   *
   * Sets right border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-right)
   */
  borderRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Border Top
   *
   * Sets top border of an image.
   *
   * Depends on: `border`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/border-top)
   */
  borderTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Brightness
   *
   * Adjusts the brightness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/bri)
   */
  bri?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Client Hints
   *
   * Sets one or more Client-Hints headers
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/ch)
   */
  ch?: InputMaybe<Array<ImgixParamsCh>>;
  /**
   * Chroma Subsampling
   *
   * Specifies the output chroma subsampling rate.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/chromasub)
   */
  chromasub?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Quantization
   *
   * Limits the number of unique colors in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/colorquant)
   */
  colorquant?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Palette Color Count
   *
   * Specifies how many colors to include in a palette-extraction response.
   *
   * Depends on: `palette`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/colors)
   */
  colors?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Contrast
   *
   * Adjusts the contrast of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/con)
   */
  con?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Corner Radius
   *
   * Specifies the radius value for a rounded corner mask.
   *
   * Depends on: `mask=corners`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/corner-radius)
   */
  cornerRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Crop Mode
   *
   * Specifies how to crop an image.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/crop)
   */
  crop?: InputMaybe<Array<ImgixParamsCrop>>;
  /**
   * Color Space
   *
   * Specifies the color space of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/cs)
   */
  cs?: InputMaybe<ImgixParamsCs>;
  /**
   * Download
   *
   * Forces a URL to use send-file in its response.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dl)
   */
  dl?: InputMaybe<Scalars['String']['input']>;
  /**
   * Dots Per Inch
   *
   * Sets the DPI value in the EXIF header.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/dpi)
   */
  dpi?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Device Pixel Ratio
   *
   * Adjusts the device-pixel ratio of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/dpr)
   */
  dpr?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Duotone
   *
   * Applies a duotone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone)
   */
  duotone?: InputMaybe<Scalars['String']['input']>;
  /**
   * Duotone Alpha
   *
   * Changes the alpha of the duotone effect atop the source image.
   *
   * Depends on: `duotone`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/duotone-alpha)
   */
  duotoneAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Exposure
   *
   * Adjusts the exposure of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/exp)
   */
  exp?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Url Expiration Timestamp
   *
   * A Unix timestamp specifying a UTC time. Requests made to this URL after that time will output a 404 status code.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/expires)
   */
  expires?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Index
   *
   * Selects a face to crop to.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faceindex)
   */
  faceindex?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Face Padding
   *
   * Adjusts padding around a selected face.
   *
   * Depends on: `fit=facearea`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/facepad)
   */
  facepad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Json Face Data
   *
   * Specifies that face data should be included in output when combined with `fm=json`.
   *
   * Depends on: `fm=json`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/face-detection/faces)
   */
  faces?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Mode
   *
   * Determines how to fill in additional space created by the fit setting
   *
   * Depends on: `fit`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill)
   */
  fill?: InputMaybe<ImgixParamsFill>;
  /**
   * Fill Color
   *
   * Sets the fill color for images with additional space created by the fit setting
   *
   * Depends on: `fill=solid`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-color)
   */
  fillColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Fallback
   *
   * Sets the fallback behavior for generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-fallback)
   */
  fillGenFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Fill Generative Negative Prompt
   *
   * Provides a negative text suggestion to the generative fill parameter. Used to reduce the probability of a subject, detail, or object appearing in generative output.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-neg-prompt)
   */
  fillGenNegPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Position
   *
   * Sets the position of the Origin Image in relation to the generative fill.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-pos)
   */
  fillGenPos?: InputMaybe<Array<ImgixParamsFillGenPos>>;
  /**
   * Fill Generative Prompt
   *
   * Provides a text suggestion to the generative fill parameter.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-prompt)
   */
  fillGenPrompt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Generative Seed
   *
   * Sets the generative seed value. Used to generate similar outputs from different prompts.
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gen-seed)
   */
  fillGenSeed?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Fill Gradient Color Space
   *
   * Defines the color space as linear, sRGB, Oklab, HSL, or LCH for gradient color interpolation
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-cs)
   */
  fillGradientCs?: InputMaybe<ImgixParamsFillGradientCs>;
  /**
   * Fill Gradient Linear
   *
   * Blends a gradient between two colors, {color1} and {color2}, along a straight path
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear)
   */
  fillGradientLinear?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Linear Direction
   *
   * The fill-gradient-linear-direction specifies the gradient's direction, flowing towards the bottom, top, right, or left
   *
   * Depends on: `fit=fill`, `fill=gen`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-linear-direction)
   */
  fillGradientLinearDirection?: InputMaybe<Array<ImgixParamsFillGradientLinearDirection>>;
  /**
   * Fill Gradient Radial
   *
   * The fill-gradient-radial parameter creates a circular gradient transitioning from a central color (Color1) to an outer color (Color2)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial)
   */
  fillGradientRadial?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial Radius
   *
   * Parameter defines the radial gradient's radius as pixels or a percentage (0.0-1.0) of the image's smallest dimension
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-radius)
   */
  fillGradientRadialRadius?: InputMaybe<Scalars['String']['input']>;
  /**
   * Fill Gradient Radial X
   *
   * Specifies the location of the radial gradient's center along the x-axis, using either a pixel value or a floating point percentage (ranging from 0.0 to 1.0) of the image's width
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-x)
   */
  fillGradientRadialX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Radial Y
   *
   * Parameter sets the radial gradient's center on the y-axis, using pixels or a 0.0 to 1.0 percentage of the image's height
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-radial-y)
   */
  fillGradientRadialY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Fill Gradient Type
   *
   * Specifies if a gradient is radial (circular) or linear (straight)
   *
   * Depends on: `fit=fill`, `fill=gradient`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/fill-gradient-type)
   */
  fillGradientType?: InputMaybe<ImgixParamsFillGradientType>;
  /**
   * Resize Fit Mode
   *
   * Specifies how to map the source image to the output image dimensions.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/fit)
   */
  fit?: InputMaybe<ImgixParamsFit>;
  /**
   * Flip Axis
   *
   * Flips an image on a specified axis.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/flip)
   */
  flip?: InputMaybe<ImgixParamsFlip>;
  /**
   * Output Format
   *
   * Changes the format of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/fm)
   */
  fm?: InputMaybe<ImgixParamsFm>;
  /**
   * Focal Point Debug
   *
   * Displays crosshairs identifying the location of the set focal point
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-debug)
   */
  fpDebug?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Focal Point X Position
   *
   * Sets the relative horizontal value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-x)
   */
  fpX?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Y Position
   *
   * Sets the relative vertical value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-y)
   */
  fpY?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Focal Point Zoom
   *
   * Sets the relative zoom value for the focal point of an image
   *
   * Depends on: `fit=crop`, `crop=focalpoint`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/focalpoint-crop/fp-z)
   */
  fpZ?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frames Per Second
   *
   * Specifies the framerate of the generated image.
   */
  fps?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Selection
   *
   * Specifies the frame of an animated image to use.
   */
  frame?: InputMaybe<Scalars['String']['input']>;
  /**
   * Gamma
   *
   * Adjusts the gamma of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/gam)
   */
  gam?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Animated Gif Quality
   *
   * Specifies the quality of the animated gif. The higher the value, the better more compression is applied.
   *
   * Depends on: `fm=gif`
   */
  gifQ?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Grid Colors
   *
   * Sets grid colors for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridColors?: InputMaybe<Scalars['String']['input']>;
  /**
   * Grid Size
   *
   * Sets grid size for the transparency checkerboard grid.
   *
   * Depends on: `transparency`
   */
  gridSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Height
   *
   * Adjusts the height of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/h)
   */
  h?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Highlight
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/high)
   */
  high?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Halftone
   *
   * Applies a half-tone effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/htn)
   */
  htn?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Hue Shift
   *
   * Adjusts the hue of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/hue)
   */
  hue?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Frame Interval
   *
   * Displays every Nth frame starting with the first frame.
   */
  interval?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Invert
   *
   * Inverts the colors on the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/invert)
   */
  invert?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Iptc Passthrough
   *
   * Determine if IPTC data should be passed for JPEG images.
   */
  iptc?: InputMaybe<ImgixParamsIptc>;
  /**
   * Jpg Progressive
   *
   * Specifies whether or not a jpg/jpeg uses progressive (true) or baseline (false)
   */
  jpgProgressive?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Animation Loop Count
   *
   * Specifies the number of times an animated image should repeat. A value of 0 means infinite looping.
   */
  loop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Lossless Compression
   *
   * Specifies that the output image should be a lossless variant.
   *
   * Depends on: `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/lossless)
   */
  lossless?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Watermark Image Url
   *
   * Specifies the location of the watermark image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark)
   */
  mark?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Alignment Mode
   *
   * Changes the watermark alignment relative to the parent image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-align)
   */
  markAlign?: InputMaybe<Array<ImgixParamsMarkAlign>>;
  /**
   * Watermark Alpha
   *
   * Changes the alpha of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-alpha)
   */
  markAlpha?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Base Url
   *
   * Changes base URL of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-base)
   */
  markBase?: InputMaybe<Scalars['String']['input']>;
  /**
   * Watermark Fit Mode
   *
   * Specifies the fit mode for watermark images.
   *
   * Depends on: `mark`, `markw`, `markh`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-fit)
   */
  markFit?: InputMaybe<ImgixParamsMarkFit>;
  /**
   * Watermark Height
   *
   * Adjusts the height of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-h)
   */
  markH?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Padding
   *
   * Applies padding to the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-pad)
   */
  markPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Rotation
   *
   * Rotates a watermark or tiled watermarks by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-rot)
   */
  markRot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark Scale
   *
   * Adjusts the scale of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-scale)
   */
  markScale?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Tile
   *
   * Adds tiled watermark.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-tile)
   */
  markTile?: InputMaybe<ImgixParamsMarkTile>;
  /**
   * Watermark Width
   *
   * Adjusts the width of the watermark image.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-w)
   */
  markW?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Watermark X Position
   *
   * Adjusts the x-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-x)
   */
  markX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Watermark Y Position
   *
   * Adjusts the y-offset of the watermark image relative to its parent.
   *
   * Depends on: `mark`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/watermark/mark-y)
   */
  markY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Mask Type
   *
   * Defines the type of mask and specifies the URL if that type is selected.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask)
   */
  mask?: InputMaybe<Scalars['String']['input']>;
  /**
   * Mask Background Color
   *
   * Colors the background of the transparent mask area of images
   *
   * Depends on: `mask`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/mask/mask-bg)
   */
  maskBg?: InputMaybe<Scalars['String']['input']>;
  /**
   * Maximum Height
   *
   * Specifies the maximum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-height)
   */
  maxH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Maximum Width
   *
   * Specifies the maximum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/max-width)
   */
  maxW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Height
   *
   * Specifies the minimum height of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-height)
   */
  minH?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Minimum Width
   *
   * Specifies the minimum width of the output image in pixels.
   *
   * Depends on: `fit=crop`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/min-width)
   */
  minW?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Monochrome
   *
   * Applies a monochrome effect to the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/monochrome)
   */
  monochrome?: InputMaybe<Scalars['String']['input']>;
  /**
   * Noise Reduction Bound
   *
   * Reduces the noise in an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nr)
   */
  nr?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Noise Reduction Sharpen
   *
   * Provides a threshold by which to sharpen an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/noise-reduction/nrs)
   */
  nrs?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Orientation
   *
   * Changes the image orientation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/orient)
   */
  orient?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding
   *
   * Pads an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad)
   */
  pad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Bottom
   *
   * Sets bottom padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-bottom)
   */
  padBottom?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Left
   *
   * Sets left padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-left)
   */
  padLeft?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Right
   *
   * Sets right padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-right)
   */
  padRight?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Padding Top
   *
   * Sets top padding of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/border-and-padding/pad-top)
   */
  padTop?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Pdf Page Number
   *
   * Selects a page from a PDF for display.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/page)
   */
  page?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Color Palette Extraction
   *
   * Specifies an output format for palette-extraction.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/palette)
   */
  palette?: InputMaybe<ImgixParamsPalette>;
  /**
   * Pdf Annotation
   *
   * Enables or disables PDF annotation.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/pdf/pdf-annotation)
   */
  pdfAnnotation?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Css Prefix
   *
   * Specifies a CSS prefix for all classes in palette-extraction.
   *
   * Depends on: `palette=css`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/color-palette/prefix)
   */
  prefix?: InputMaybe<Scalars['String']['input']>;
  /**
   * Pixellate
   *
   * Applies a pixelation effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/px)
   */
  px?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Output Quality
   *
   * Adjusts the quality of an output image.
   *
   * Depends on: `fm=jpg`, `fm=pjpg`, `fm=webp`, `fm=jxr`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/format/q)
   */
  q?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Source Rectangle Region
   *
   * Crops an image to a specified rectangle.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/rect)
   */
  rect?: InputMaybe<Scalars['String']['input']>;
  /**
   * Reverse
   *
   * Reverses the frame order on the source animation.
   */
  reverse?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Rotation
   *
   * Rotates an image by a specified number of degrees.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/rotation/rot)
   */
  rot?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Saturation
   *
   * Adjusts the saturation of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sat)
   */
  sat?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Sepia Tone
   *
   * Applies a sepia effect to an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/stylize/sepia)
   */
  sepia?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Shadow
   *
   * Adjusts the highlights of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/shad)
   */
  shad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Sharpen
   *
   * Adjusts the sharpness of the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/sharp)
   */
  sharp?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Frame Skip
   *
   * Skips every Nth frame starting with the first frame.
   */
  skip?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Bypasses any [DatoCMS Automatic Image Optimization](https://www.datocms.com/docs/cdn-settings/advanced-asset-settings) that might be set up for the project.
   *
   * Exercise caution when using this parameter, as it could significantly increase your bandwidth costs.
   */
  skipDefaultOptimizations?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Sanitize Svg
   *
   * Specifies whether to sanitize an SVG.
   */
  svgSanitize?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Transparency
   *
   * Adds checkerboard behind images which support transparency.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/fill/transparency)
   */
  transparency?: InputMaybe<ImgixParamsTransparency>;
  /**
   * Trim Image
   *
   * Trims the source image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim)
   */
  trim?: InputMaybe<ImgixParamsTrim>;
  /**
   * Trim Color
   *
   * Specifies a trim color on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-color)
   */
  trimColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Trim Mean Difference
   *
   * Specifies the mean difference on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-md)
   */
  trimMd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Padding
   *
   * Pads the area of the source image before trimming.
   *
   * Depends on: `trim`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-pad)
   */
  trimPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Trim Standard Deviation
   *
   * Specifies the standard deviation on a trim operation.
   *
   * Depends on: `trim=auto`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-sd)
   */
  trimSd?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Trim Tolerance
   *
   * Specifies the tolerance on a trim operation.
   *
   * Depends on: `trim=color`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/trim/trim-tol)
   */
  trimTol?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text String
   *
   * Sets the text string to render.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt)
   */
  txt?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Align
   *
   * Sets the vertical and horizontal alignment of rendered text relative to the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-align)
   */
  txtAlign?: InputMaybe<Array<ImgixParamsTxtAlign>>;
  /**
   * Text Clipping Mode
   *
   * Sets the clipping properties of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-clip)
   */
  txtClip?: InputMaybe<Array<ImgixParamsTxtClip>>;
  /**
   * Text Color
   *
   * Specifies the color of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-color)
   */
  txtColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Fit Mode
   *
   * Specifies the fit approach for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-fit)
   */
  txtFit?: InputMaybe<ImgixParamsTxtFit>;
  /**
   * Text Font
   *
   * Selects a font for rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-font)
   */
  txtFont?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Leading
   *
   * Sets the leading (line spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-lead)
   */
  txtLead?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline
   *
   * Outlines the rendered text with a specified color.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line)
   */
  txtLine?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Outline Color
   *
   * Specifies a text outline color.
   *
   * Depends on: `txt`, `txtline`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-line-color)
   */
  txtLineColor?: InputMaybe<Scalars['String']['input']>;
  /**
   * Text Padding
   *
   * Specifies the padding (in device-independent pixels) between a textbox and the edges of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-pad)
   */
  txtPad?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Shadow
   *
   * Applies a shadow to rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-shad)
   */
  txtShad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Text Font Size
   *
   * Sets the font size of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-size)
   */
  txtSize?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Tracking
   *
   * Sets the tracking (letter spacing) for rendered text. Only works on the multi-line text endpoint.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/typesetting/txt-track)
   */
  txtTrack?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Width
   *
   * Sets the width of rendered text.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-width)
   */
  txtWidth?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text X Position
   *
   * Sets the horizontal (x) position of the text in pixels relative to the left edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-x)
   */
  txtX?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Text Y Position
   *
   * Sets the vertical (y) position of the text in pixels relative to the top edge of the base image.
   *
   * Depends on: `txt`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/text/txt-y)
   */
  txtY?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Super Resolution
   *
   * Uses generative AI fill to upscale low resolution images.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale)
   */
  upscale?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Super Resolution Fallback
   *
   * Overrides default fallback behavior for super resolution failures
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/rendering/super-resolution/upscale-fallback)
   */
  upscaleFallback?: InputMaybe<Scalars['BooleanType']['input']>;
  /**
   * Unsharp Mask
   *
   * Sharpens the source image using an unsharp mask.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usm)
   */
  usm?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Unsharp Mask Radius
   *
   * Specifies the radius for an unsharp mask operation.
   *
   * Depends on: `usm`
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/usmrad)
   */
  usmrad?: InputMaybe<Scalars['FloatType']['input']>;
  /**
   * Vibrance
   *
   * Adjusts the vibrance of an image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/adjustment/vib)
   */
  vib?: InputMaybe<Scalars['IntType']['input']>;
  /**
   * Image Width
   *
   * Adjusts the width of the output image.
   *
   * [Open Imgix reference »](https://docs.imgix.com/apis/url/size/w)
   */
  w?: InputMaybe<Scalars['FloatType']['input']>;
};

export enum ImgixParamsAuto {
  Compress = 'compress',
  Enhance = 'enhance',
  Format = 'format',
  Redeye = 'redeye'
}

export enum ImgixParamsBlendAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendCrop {
  Bottom = 'bottom',
  Faces = 'faces',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsBlendFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsBlendMode {
  Burn = 'burn',
  Color = 'color',
  Darken = 'darken',
  Difference = 'difference',
  Dodge = 'dodge',
  Exclusion = 'exclusion',
  Hardlight = 'hardlight',
  Hue = 'hue',
  Lighten = 'lighten',
  Luminosity = 'luminosity',
  Multiply = 'multiply',
  Normal = 'normal',
  Overlay = 'overlay',
  Saturation = 'saturation',
  Screen = 'screen',
  Softlight = 'softlight'
}

export enum ImgixParamsBlendSize {
  Inherit = 'inherit'
}

export enum ImgixParamsCh {
  Dpr = 'dpr',
  SaveData = 'saveData',
  Width = 'width'
}

export enum ImgixParamsCrop {
  Bottom = 'bottom',
  Edges = 'edges',
  Entropy = 'entropy',
  Faces = 'faces',
  Focalpoint = 'focalpoint',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsCs {
  Adobergb1998 = 'adobergb1998',
  Srgb = 'srgb',
  Strip = 'strip',
  Tinysrgb = 'tinysrgb'
}

export enum ImgixParamsFill {
  Blur = 'blur',
  Gen = 'gen',
  Generative = 'generative',
  Gradient = 'gradient',
  Solid = 'solid'
}

export enum ImgixParamsFillGenPos {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientCs {
  Hsl = 'hsl',
  Lch = 'lch',
  Linear = 'linear',
  Oklab = 'oklab',
  Srgb = 'srgb'
}

export enum ImgixParamsFillGradientLinearDirection {
  Bottom = 'bottom',
  Left = 'left',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsFillGradientType {
  Linear = 'linear',
  Radial = 'radial'
}

export enum ImgixParamsFit {
  Clamp = 'clamp',
  Clip = 'clip',
  Crop = 'crop',
  Facearea = 'facearea',
  Fill = 'fill',
  Fillmax = 'fillmax',
  Max = 'max',
  Min = 'min',
  Scale = 'scale'
}

export enum ImgixParamsFlip {
  H = 'h',
  Hv = 'hv',
  V = 'v'
}

export enum ImgixParamsFm {
  Avif = 'avif',
  Blurhash = 'blurhash',
  Gif = 'gif',
  Jp2 = 'jp2',
  Jpg = 'jpg',
  Json = 'json',
  Jxr = 'jxr',
  Mp4 = 'mp4',
  Pjpg = 'pjpg',
  Png = 'png',
  Png8 = 'png8',
  Png32 = 'png32',
  Webm = 'webm',
  Webp = 'webp'
}

export enum ImgixParamsIptc {
  Allow = 'allow',
  Block = 'block'
}

export enum ImgixParamsMarkAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsMarkFit {
  Clip = 'clip',
  Crop = 'crop',
  Fill = 'fill',
  Max = 'max',
  Scale = 'scale'
}

export enum ImgixParamsMarkTile {
  Grid = 'grid'
}

export enum ImgixParamsPalette {
  Css = 'css',
  Json = 'json'
}

export enum ImgixParamsTransparency {
  Grid = 'grid'
}

export enum ImgixParamsTrim {
  Auto = 'auto',
  Color = 'color'
}

export enum ImgixParamsTxtAlign {
  Bottom = 'bottom',
  Center = 'center',
  Left = 'left',
  Middle = 'middle',
  Right = 'right',
  Top = 'top'
}

export enum ImgixParamsTxtClip {
  Ellipsis = 'ellipsis',
  End = 'end',
  Middle = 'middle',
  Start = 'start'
}

export enum ImgixParamsTxtFit {
  Max = 'max'
}

/** Specifies how to filter by usage */
export type InUseFilter = {
  /** Search uploads that are currently used by some record or not */
  eq?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Specifies how to filter by ID */
export type ItemIdFilter = {
  /** Search the record with the specified ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Exclude the record with the specified ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Search records that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

export enum ItemStatus {
  Draft = 'draft',
  Published = 'published',
  Updated = 'updated'
}

/** Specifies how to filter Single-link fields */
export type LinkFilter = {
  /** Search for records with an exact match. The specified value must be a Record ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records linked to one of the specified records */
  in?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
  /** Exclude records with an exact match. The specified value must be a Record ID */
  neq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Filter records not linked to one of the specified records */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['ItemId']['input']>>>;
};

/** Block of type Logos group (logos_group) */
export type LogosGroupRecord = RecordInterface & {
  __typename?: 'LogosGroupRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  logos: Array<FileField>;
};

/** Block of type Logos group (logos_group) */
export type LogosGroupRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export enum MuxThumbnailFormatType {
  Gif = 'gif',
  Jpg = 'jpg',
  Png = 'png'
}

export type NavitemModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<NavitemModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<NavitemModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  contentPageLink?: InputMaybe<LinkFilter>;
  externalUrl?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  internalLink?: InputMaybe<StringFilter>;
  parent?: InputMaybe<ParentFilter>;
  position?: InputMaybe<PositionFilter>;
  title?: InputMaybe<StringFilter>;
};

export enum NavitemModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ExternalUrlAsc = 'externalUrl_ASC',
  ExternalUrlDesc = 'externalUrl_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  InternalLinkAsc = 'internalLink_ASC',
  InternalLinkDesc = 'internalLink_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC'
}

/** Record of type NavItem (navitem) */
export type NavitemRecord = RecordInterface & {
  __typename?: 'NavitemRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  children: Array<NavitemRecord>;
  contentPageLink?: Maybe<ContentPageRecord>;
  externalUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  internalLink?: Maybe<Scalars['String']['output']>;
  parent?: Maybe<NavitemRecord>;
  position?: Maybe<Scalars['IntType']['output']>;
  title?: Maybe<Scalars['String']['output']>;
};

/** Record of type NavItem (navitem) */
export type NavitemRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by image orientation */
export type OrientationFilter = {
  /** Search uploads with the specified orientation */
  eq?: InputMaybe<UploadOrientation>;
  /** Exclude uploads with the specified orientation */
  neq?: InputMaybe<UploadOrientation>;
};

/** Specifies how to filter by parent (tree-like collections only) */
export type ParentFilter = {
  /** Filter records children of the specified record. Value must be a Record ID */
  eq?: InputMaybe<Scalars['ItemId']['input']>;
  /** Filter records with a parent record or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Specifies how to filter by position (sorted and tree-like collections) */
export type PositionFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Filter records with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by publication datetime */
export type PublishedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** The query root for this schema */
export type Query = {
  __typename?: 'Query';
  /** Returns meta information regarding a record collection */
  _allBlogpostCtasMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allCaseStudiesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allContentPagesMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allNavitemsMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTeamMembersMeta: CollectionMetadata;
  /** Returns meta information regarding a record collection */
  _allTestimonialsMeta: CollectionMetadata;
  /** Returns meta information regarding an assets collection */
  _allUploadsMeta: CollectionMetadata;
  /** Returns the single instance record */
  _site: Site;
  /** Returns a collection of records */
  allBlogpostCtas: Array<BlogpostCtaRecord>;
  /** Returns a collection of records */
  allCaseStudies: Array<CaseStudyRecord>;
  /** Returns a collection of records */
  allContentPages: Array<ContentPageRecord>;
  /** Returns a collection of records */
  allNavitems: Array<NavitemRecord>;
  /** Returns a collection of records */
  allTeamMembers: Array<TeamMemberRecord>;
  /** Returns a collection of records */
  allTestimonials: Array<TestimonialRecord>;
  /** Returns a collection of assets */
  allUploads: Array<FileField>;
  /** Returns the single instance record */
  blogPage?: Maybe<BlogPageRecord>;
  /** Returns a specific record */
  blogpostCta?: Maybe<BlogpostCtaRecord>;
  /** Returns the single instance record */
  caseStudiesPage?: Maybe<CaseStudiesPageRecord>;
  /** Returns a specific record */
  caseStudy?: Maybe<CaseStudyRecord>;
  /** Returns the single instance record */
  companyPage?: Maybe<CompanyPageRecord>;
  /** Returns a specific record */
  contentPage?: Maybe<ContentPageRecord>;
  /** Returns a specific record */
  navitem?: Maybe<NavitemRecord>;
  /** Returns a specific record */
  teamMember?: Maybe<TeamMemberRecord>;
  /** Returns the single instance record */
  teamPage?: Maybe<TeamPageRecord>;
  /** Returns a specific record */
  testimonial?: Maybe<TestimonialRecord>;
  /** Returns a specific asset */
  upload?: Maybe<FileField>;
};

/** The query root for this schema */
export type Query_AllBlogpostCtasMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BlogpostCtaModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllCaseStudiesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CaseStudyModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllContentPagesMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ContentPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllNavitemsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NavitemModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTeamMembersMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TeamMemberModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllTestimonialsMetaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TestimonialModelFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_AllUploadsMetaArgs = {
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type Query_SiteArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryAllBlogpostCtasArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BlogpostCtaModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogpostCtaModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryAllCaseStudiesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CaseStudyModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CaseStudyModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryAllContentPagesArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ContentPageModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentPageModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryAllNavitemsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NavitemModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NavitemModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryAllTeamMembersArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TeamMemberModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryAllTestimonialsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TestimonialModelFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TestimonialModelOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryAllUploadsArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  first?: InputMaybe<Scalars['IntType']['input']>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
  skip?: InputMaybe<Scalars['IntType']['input']>;
};

/** The query root for this schema */
export type QueryBlogPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryBlogpostCtaArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<BlogpostCtaModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<BlogpostCtaModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryCaseStudiesPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryCaseStudyArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<CaseStudyModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<CaseStudyModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryCompanyPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryContentPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<ContentPageModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<ContentPageModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryNavitemArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<NavitemModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<NavitemModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTeamMemberArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TeamMemberModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TeamMemberModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryTeamPageArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** The query root for this schema */
export type QueryTestimonialArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<TestimonialModelFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<TestimonialModelOrderBy>>>;
};

/** The query root for this schema */
export type QueryUploadArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  filter?: InputMaybe<UploadFilter>;
  locale?: InputMaybe<SiteLocale>;
  orderBy?: InputMaybe<Array<InputMaybe<UploadOrderBy>>>;
};

export type QuoteModelContentField = {
  __typename?: 'QuoteModelContentField';
  blocks: Array<Scalars['String']['output']>;
  links: Array<Scalars['String']['output']>;
  value: Scalars['JsonField']['output'];
};

/** Block of type Quote (quote) */
export type QuoteRecord = RecordInterface & {
  __typename?: 'QuoteRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  avatar?: Maybe<FileField>;
  content?: Maybe<QuoteModelContentField>;
  id: Scalars['ItemId']['output'];
  name?: Maybe<Scalars['String']['output']>;
};

/** Block of type Quote (quote) */
export type QuoteRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type RecordInterface = {
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
};

export type RecordInterface_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type ResolutionFilter = {
  /** Search uploads with the specified resolution */
  eq?: InputMaybe<ResolutionType>;
  /** Search uploads with the specified resolutions */
  in?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
  /** Exclude uploads with the specified resolution */
  neq?: InputMaybe<ResolutionType>;
  /** Search uploads without the specified resolutions */
  notIn?: InputMaybe<Array<InputMaybe<ResolutionType>>>;
};

export enum ResolutionType {
  Icon = 'icon',
  Large = 'large',
  Medium = 'medium',
  Small = 'small'
}

export type ResponsiveImage = {
  __typename?: 'ResponsiveImage';
  alt?: Maybe<Scalars['String']['output']>;
  aspectRatio: Scalars['FloatType']['output'];
  base64?: Maybe<Scalars['String']['output']>;
  bgColor?: Maybe<Scalars['String']['output']>;
  height: Scalars['IntType']['output'];
  sizes: Scalars['String']['output'];
  src: Scalars['String']['output'];
  srcSet: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  webpSrcSet: Scalars['String']['output'];
  width: Scalars['IntType']['output'];
};

/** Block of type Reviewer (reviewer) */
export type ReviewerRecord = RecordInterface & {
  __typename?: 'ReviewerRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  avatar?: Maybe<FileField>;
  id: Scalars['ItemId']['output'];
  name?: Maybe<Scalars['String']['output']>;
  workPosition?: Maybe<Scalars['String']['output']>;
};

/** Block of type Reviewer (reviewer) */
export type ReviewerRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type SeoField = {
  __typename?: 'SeoField';
  description?: Maybe<Scalars['String']['output']>;
  image?: Maybe<FileField>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  twitterCard?: Maybe<Scalars['String']['output']>;
};

/** Specifies how to filter SEO meta tags fields */
export type SeoFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
};

export type Site = {
  __typename?: 'Site';
  favicon?: Maybe<FileField>;
  faviconMetaTags: Array<Tag>;
  globalSeo?: Maybe<GlobalSeoField>;
  locales: Array<SiteLocale>;
  noIndex?: Maybe<Scalars['BooleanType']['output']>;
};

export type SiteFaviconMetaTagsArgs = {
  variants?: InputMaybe<Array<InputMaybe<FaviconType>>>;
};

export type SiteGlobalSeoArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export enum SiteLocale {
  En = 'en'
}

/** Specifies how to filter Slug fields */
export type SlugFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that have one of the specified slugs */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do have one of the specified slugs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by status */
export type StatusFilter = {
  /** Search the record with the specified status */
  eq?: InputMaybe<ItemStatus>;
  /** Search records with the specified statuses */
  in?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
  /** Exclude the record with the specified status */
  neq?: InputMaybe<ItemStatus>;
  /** Search records without the specified statuses */
  notIn?: InputMaybe<Array<InputMaybe<ItemStatus>>>;
};

/** Specifies how to filter Single-line string fields */
export type StringFilter = {
  /** Search for records with an exact match */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records that equal one of the specified values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter records with the specified field set as blank (null or empty string) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records with an exact match */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Filter records that do not equal one of the specified values */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type StringMatchesFilter = {
  caseSensitive?: InputMaybe<Scalars['BooleanType']['input']>;
  pattern: Scalars['String']['input'];
  regexp?: InputMaybe<Scalars['BooleanType']['input']>;
};

/** Specifies how to filter Structured Text fields values */
export type StructuredTextFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not [DEPRECATED] */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field set as blank (null or single empty paragraph) */
  isBlank?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with the specified field present (neither null, nor empty string) */
  isPresent?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude records based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type Tag = {
  __typename?: 'Tag';
  attributes?: Maybe<Scalars['MetaTagAttributes']['output']>;
  content?: Maybe<Scalars['String']['output']>;
  tag: Scalars['String']['output'];
};

export type TeamMemberModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TeamMemberModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TeamMemberModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  active?: InputMaybe<BooleanFilter>;
  appearOnFirstLine?: InputMaybe<BooleanFilter>;
  ghostId?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  image?: InputMaybe<FileFilter>;
  name?: InputMaybe<StringFilter>;
  position?: InputMaybe<PositionFilter>;
  workPosition?: InputMaybe<StringFilter>;
};

export enum TeamMemberModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  ActiveAsc = 'active_ASC',
  ActiveDesc = 'active_DESC',
  AppearOnFirstLineAsc = 'appearOnFirstLine_ASC',
  AppearOnFirstLineDesc = 'appearOnFirstLine_DESC',
  GhostIdAsc = 'ghostId_ASC',
  GhostIdDesc = 'ghostId_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PositionAsc = 'position_ASC',
  PositionDesc = 'position_DESC',
  WorkPositionAsc = 'workPosition_ASC',
  WorkPositionDesc = 'workPosition_DESC'
}

/** Record of type Team Member (team_member) */
export type TeamMemberRecord = RecordInterface & {
  __typename?: 'TeamMemberRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  active?: Maybe<Scalars['BooleanType']['output']>;
  appearOnFirstLine?: Maybe<Scalars['BooleanType']['output']>;
  ghostId?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  image: FileField;
  name: Scalars['String']['output'];
  position?: Maybe<Scalars['IntType']['output']>;
  workPosition: Scalars['String']['output'];
};

/** Record of type Team Member (team_member) */
export type TeamMemberRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Record of type Team Page (team_page) */
export type TeamPageRecord = RecordInterface & {
  __typename?: 'TeamPageRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  content: Array<ContentBlockRecord>;
  id: Scalars['ItemId']['output'];
  seo?: Maybe<SeoField>;
};

/** Record of type Team Page (team_page) */
export type TeamPageRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type TestimonialModelFilter = {
  AND?: InputMaybe<Array<InputMaybe<TestimonialModelFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<TestimonialModelFilter>>>;
  _createdAt?: InputMaybe<CreatedAtFilter>;
  _firstPublishedAt?: InputMaybe<PublishedAtFilter>;
  _isValid?: InputMaybe<BooleanFilter>;
  _publicationScheduledAt?: InputMaybe<PublishedAtFilter>;
  _publishedAt?: InputMaybe<PublishedAtFilter>;
  _status?: InputMaybe<StatusFilter>;
  _unpublishingScheduledAt?: InputMaybe<PublishedAtFilter>;
  _updatedAt?: InputMaybe<UpdatedAtFilter>;
  companyPosition?: InputMaybe<StringFilter>;
  id?: InputMaybe<ItemIdFilter>;
  review?: InputMaybe<StringFilter>;
  reviewerAvatar?: InputMaybe<FileFilter>;
  reviewerName?: InputMaybe<StringFilter>;
};

export enum TestimonialModelOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  FirstPublishedAtAsc = '_firstPublishedAt_ASC',
  FirstPublishedAtDesc = '_firstPublishedAt_DESC',
  IsValidAsc = '_isValid_ASC',
  IsValidDesc = '_isValid_DESC',
  PublicationScheduledAtAsc = '_publicationScheduledAt_ASC',
  PublicationScheduledAtDesc = '_publicationScheduledAt_DESC',
  PublishedAtAsc = '_publishedAt_ASC',
  PublishedAtDesc = '_publishedAt_DESC',
  StatusAsc = '_status_ASC',
  StatusDesc = '_status_DESC',
  UnpublishingScheduledAtAsc = '_unpublishingScheduledAt_ASC',
  UnpublishingScheduledAtDesc = '_unpublishingScheduledAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  CompanyPositionAsc = 'companyPosition_ASC',
  CompanyPositionDesc = 'companyPosition_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  ReviewAsc = 'review_ASC',
  ReviewDesc = 'review_DESC',
  ReviewerNameAsc = 'reviewerName_ASC',
  ReviewerNameDesc = 'reviewerName_DESC'
}

/** Record of type Testimonial (testimonial) */
export type TestimonialRecord = RecordInterface & {
  __typename?: 'TestimonialRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  companyPosition?: Maybe<Scalars['String']['output']>;
  id: Scalars['ItemId']['output'];
  review?: Maybe<Scalars['String']['output']>;
  reviewerAvatar?: Maybe<FileField>;
  reviewerName?: Maybe<Scalars['String']['output']>;
};

/** Record of type Testimonial (testimonial) */
export type TestimonialRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

export type TextSnippetModelContentBlocksField = CtaButtonRecord | ReviewerRecord;

export type TextSnippetModelContentField = {
  __typename?: 'TextSnippetModelContentField';
  blocks: Array<TextSnippetModelContentBlocksField>;
  links: Array<CaseStudyRecord>;
  value: Scalars['JsonField']['output'];
};

/** Block of type Text Snippet (text_snippet) */
export type TextSnippetRecord = RecordInterface & {
  __typename?: 'TextSnippetRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  caseStudy?: Maybe<CaseStudyRecord>;
  content?: Maybe<TextSnippetModelContentField>;
  id: Scalars['ItemId']['output'];
  image?: Maybe<FileField>;
};

/** Block of type Text Snippet (text_snippet) */
export type TextSnippetRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Three by Three Table (three_by_three_table) */
export type ThreeByThreeTableRecord = RecordInterface & {
  __typename?: 'ThreeByThreeTableRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  image?: Maybe<FileField>;
  threeByThreeTable: Array<TextSnippetRecord>;
};

/** Block of type Three by Three Table (three_by_three_table) */
export type ThreeByThreeTableRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Block of type Two by two Table (two_by_two_table) */
export type TwoByTwoTableRecord = RecordInterface & {
  __typename?: 'TwoByTwoTableRecord';
  _createdAt: Scalars['DateTime']['output'];
  /** Editing URL */
  _editingUrl?: Maybe<Scalars['String']['output']>;
  _firstPublishedAt?: Maybe<Scalars['DateTime']['output']>;
  _isValid: Scalars['BooleanType']['output'];
  _modelApiKey: Scalars['String']['output'];
  _publicationScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _publishedAt?: Maybe<Scalars['DateTime']['output']>;
  /** Generates SEO and Social card meta tags to be used in your frontend */
  _seoMetaTags: Array<Tag>;
  _status: ItemStatus;
  _unpublishingScheduledAt?: Maybe<Scalars['DateTime']['output']>;
  _updatedAt: Scalars['DateTime']['output'];
  id: Scalars['ItemId']['output'];
  twoByTwoContent: Array<TextSnippetRecord>;
};

/** Block of type Two by two Table (two_by_two_table) */
export type TwoByTwoTableRecord_SeoMetaTagsArgs = {
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by upload type */
export type TypeFilter = {
  /** Search uploads with the specified type */
  eq?: InputMaybe<UploadType>;
  /** Search uploads with the specified types */
  in?: InputMaybe<Array<InputMaybe<UploadType>>>;
  /** Exclude uploads with the specified type */
  neq?: InputMaybe<UploadType>;
  /** Search uploads without the specified types */
  notIn?: InputMaybe<Array<InputMaybe<UploadType>>>;
};

/** Specifies how to filter by update datetime */
export type UpdatedAtFilter = {
  /** Filter records with a value that's within the specified minute range. Seconds and milliseconds are truncated from the argument. */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter records with a value that's strictly greater than the one specified. Seconds and milliseconds are truncated from the argument. */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's greater than or equal to than the one specified. Seconds and milliseconds are truncated from the argument. */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less than the one specified. Seconds and milliseconds are truncated from the argument. */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's less or equal than the one specified. Seconds and milliseconds are truncated from the argument. */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter records with a value that's outside the specified minute range. Seconds and milliseconds are truncated from the argument. */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by default alt */
export type UploadAltFilter = {
  /** Search the uploads with the specified alt */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search uploads with the specified values as default alt */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the uploads with the specified alt */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search uploads that do not have the specified values as default alt */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by auhtor */
export type UploadAuthorFilter = {
  /** Filter uploads with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by basename */
export type UploadBasenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by colors */
export type UploadColorsFilter = {
  /** Filter uploads that have all of the specified colors */
  allIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have at least one of the specified colors */
  anyIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that have the specified colors */
  contains?: InputMaybe<ColorBucketType>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
  /** Filter uploads that do not have any of the specified colors */
  notIn?: InputMaybe<Array<InputMaybe<ColorBucketType>>>;
};

/** Specifies how to filter by copyright */
export type UploadCopyrightFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by creation datetime */
export type UploadCreatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

/** Specifies how to filter by filename */
export type UploadFilenameFilter = {
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export type UploadFilter = {
  AND?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  OR?: InputMaybe<Array<InputMaybe<UploadFilter>>>;
  _createdAt?: InputMaybe<UploadCreatedAtFilter>;
  _updatedAt?: InputMaybe<UploadUpdatedAtFilter>;
  alt?: InputMaybe<UploadAltFilter>;
  author?: InputMaybe<UploadAuthorFilter>;
  basename?: InputMaybe<UploadBasenameFilter>;
  colors?: InputMaybe<UploadColorsFilter>;
  copyright?: InputMaybe<UploadCopyrightFilter>;
  filename?: InputMaybe<UploadFilenameFilter>;
  format?: InputMaybe<UploadFormatFilter>;
  height?: InputMaybe<UploadHeightFilter>;
  id?: InputMaybe<UploadIdFilter>;
  inUse?: InputMaybe<InUseFilter>;
  md5?: InputMaybe<UploadMd5Filter>;
  mimeType?: InputMaybe<UploadMimeTypeFilter>;
  notes?: InputMaybe<UploadNotesFilter>;
  orientation?: InputMaybe<OrientationFilter>;
  resolution?: InputMaybe<ResolutionFilter>;
  size?: InputMaybe<UploadSizeFilter>;
  smartTags?: InputMaybe<UploadTagsFilter>;
  tags?: InputMaybe<UploadTagsFilter>;
  title?: InputMaybe<UploadTitleFilter>;
  type?: InputMaybe<TypeFilter>;
  width?: InputMaybe<UploadWidthFilter>;
};

/** Specifies how to filter by format */
export type UploadFormatFilter = {
  /** Search the asset with the specified format */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified formats */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified format */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified formats */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by height */
export type UploadHeightFilter = {
  /** Search assets with the specified height */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified height */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified height */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified height */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified height */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by ID */
export type UploadIdFilter = {
  /** Search the asset with the specified ID */
  eq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets with the specified IDs */
  in?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
  /** Exclude the asset with the specified ID */
  neq?: InputMaybe<Scalars['UploadId']['input']>;
  /** Search assets that do not have the specified IDs */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['UploadId']['input']>>>;
};

/** Specifies how to filter by MD5 */
export type UploadMd5Filter = {
  /** Search the asset with the specified MD5 */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified MD5s */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude the asset with the specified MD5 */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified MD5s */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Specifies how to filter by mime type */
export type UploadMimeTypeFilter = {
  /** Search the asset with the specified mime type */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets with the specified mime types */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified mime type */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified mime types */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

/** Specifies how to filter by notes */
export type UploadNotesFilter = {
  /** Filter records with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadOrderBy {
  CreatedAtAsc = '_createdAt_ASC',
  CreatedAtDesc = '_createdAt_DESC',
  UpdatedAtAsc = '_updatedAt_ASC',
  UpdatedAtDesc = '_updatedAt_DESC',
  BasenameAsc = 'basename_ASC',
  BasenameDesc = 'basename_DESC',
  FilenameAsc = 'filename_ASC',
  FilenameDesc = 'filename_DESC',
  FormatAsc = 'format_ASC',
  FormatDesc = 'format_DESC',
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
  ResolutionAsc = 'resolution_ASC',
  ResolutionDesc = 'resolution_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC'
}

export enum UploadOrientation {
  Landscape = 'landscape',
  Portrait = 'portrait',
  Square = 'square'
}

/** Specifies how to filter by size */
export type UploadSizeFilter = {
  /** Search assets with the specified size (in bytes) */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified size (in bytes) */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified size (in bytes) */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified size (in bytes) */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified size (in bytes) */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

/** Specifies how to filter by tags */
export type UploadTagsFilter = {
  /** Filter uploads linked to all of the specified tags */
  allIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to at least one of the specified tags */
  anyIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads linked to the specified tag */
  contains?: InputMaybe<Scalars['String']['input']>;
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Filter uploads not linked to any of the specified tags */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Specifies how to filter by default title */
export type UploadTitleFilter = {
  /** Search the asset with the specified title */
  eq?: InputMaybe<Scalars['String']['input']>;
  /** Filter assets with the specified field defined (i.e. with any value) or not */
  exists?: InputMaybe<Scalars['BooleanType']['input']>;
  /** Search assets with the specified as default title */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Filter uploads based on a regular expression */
  matches?: InputMaybe<StringMatchesFilter>;
  /** Exclude the asset with the specified title */
  neq?: InputMaybe<Scalars['String']['input']>;
  /** Search assets that do not have the specified as default title */
  notIn?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  /** Exclude uploads based on a regular expression */
  notMatches?: InputMaybe<StringMatchesFilter>;
};

export enum UploadType {
  Archive = 'archive',
  Audio = 'audio',
  Image = 'image',
  Pdfdocument = 'pdfdocument',
  Presentation = 'presentation',
  Richtext = 'richtext',
  Spreadsheet = 'spreadsheet',
  Video = 'video'
}

/** Specifies how to filter by update datetime */
export type UploadUpdatedAtFilter = {
  /** Search for uploads with an exact match */
  eq?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's strictly greater than the one specified */
  gt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's greater than or equal to the one specified */
  gte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less than the one specified */
  lt?: InputMaybe<Scalars['DateTime']['input']>;
  /** Filter uploads with a value that's less or equal than the one specified */
  lte?: InputMaybe<Scalars['DateTime']['input']>;
  /** Exclude uploads with an exact match */
  neq?: InputMaybe<Scalars['DateTime']['input']>;
};

export type UploadVideoField = {
  __typename?: 'UploadVideoField';
  alt?: Maybe<Scalars['String']['output']>;
  blurUpThumb?: Maybe<Scalars['String']['output']>;
  blurhash?: Maybe<Scalars['String']['output']>;
  duration?: Maybe<Scalars['Int']['output']>;
  framerate?: Maybe<Scalars['Int']['output']>;
  height: Scalars['IntType']['output'];
  mp4Url?: Maybe<Scalars['String']['output']>;
  muxAssetId: Scalars['String']['output'];
  muxPlaybackId: Scalars['String']['output'];
  streamingUrl: Scalars['String']['output'];
  thumbhash?: Maybe<Scalars['String']['output']>;
  thumbnailUrl: Scalars['String']['output'];
  title?: Maybe<Scalars['String']['output']>;
  width: Scalars['IntType']['output'];
};

export type UploadVideoFieldAltArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

export type UploadVideoFieldBlurUpThumbArgs = {
  imgixParams?: InputMaybe<ImgixParams>;
  punch?: Scalars['Float']['input'];
  quality?: Scalars['Int']['input'];
  size?: Scalars['Int']['input'];
};

export type UploadVideoFieldMp4UrlArgs = {
  exactRes?: InputMaybe<VideoMp4Res>;
  res?: InputMaybe<VideoMp4Res>;
};

export type UploadVideoFieldThumbnailUrlArgs = {
  format?: InputMaybe<MuxThumbnailFormatType>;
};

export type UploadVideoFieldTitleArgs = {
  fallbackLocales?: InputMaybe<Array<SiteLocale>>;
  locale?: InputMaybe<SiteLocale>;
};

/** Specifies how to filter by width */
export type UploadWidthFilter = {
  /** Search assets with the specified width */
  eq?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger than the specified width */
  gt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  gte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets smaller than the specified width */
  lt?: InputMaybe<Scalars['IntType']['input']>;
  /** Search all assets larger or equal to the specified width */
  lte?: InputMaybe<Scalars['IntType']['input']>;
  /** Search assets that do not have the specified width */
  neq?: InputMaybe<Scalars['IntType']['input']>;
};

export enum VideoMp4Res {
  High = 'high',
  Low = 'low',
  Medium = 'medium'
}

export type FocalPoint = {
  __typename?: 'focalPoint';
  x: Scalars['FloatType']['output'];
  y: Scalars['FloatType']['output'];
};

export type AllCaseStudySlugsQueryVariables = Exact<{ [key: string]: never }>;

export type AllCaseStudySlugsQuery = {
  __typename?: 'Query';
  allCaseStudies: Array<{ __typename?: 'CaseStudyRecord'; slug?: string | undefined }>;
};

export type AllContentPagesQueryVariables = Exact<{ [key: string]: never }>;

export type AllContentPagesQuery = {
  __typename?: 'Query';
  allContentPages: Array<{ __typename?: 'ContentPageRecord'; slug?: string | undefined }>;
};

export type AllNavitemsQueryVariables = Exact<{ [key: string]: never }>;

export type AllNavitemsQuery = {
  __typename?: 'Query';
  allNavitems: Array<{
    __typename?: 'NavitemRecord';
    externalUrl?: string | undefined;
    internalLink?: string | undefined;
    title?: string | undefined;
    contentPageLink?:
      | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
      | undefined;
    children: Array<{
      __typename?: 'NavitemRecord';
      externalUrl?: string | undefined;
      internalLink?: string | undefined;
      title?: string | undefined;
      contentPageLink?:
        | {
            __typename?: 'ContentPageRecord';
            slug?: string | undefined;
            title?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type BlogPostQueryVariables = Exact<{ [key: string]: never }>;

export type BlogPostQuery = {
  __typename?: 'Query';
  blogpostCta?:
    | {
        __typename?: 'BlogpostCtaRecord';
        content: Array<{
          __typename: 'ContentBlockRecord';
          imageSize?: number | undefined;
          imageMargin?: number | undefined;
          content?:
            | {
                __typename?: 'ContentBlockModelContentField';
                value: unknown;
                blocks: Array<
                  | {
                      __typename: 'CtaButtonRecord';
                      id: string;
                      buttonLink?: string | undefined;
                      buttonText?: string | undefined;
                      newTab?: boolean | undefined;
                      trackingId?: string | undefined;
                    }
                  | {
                      __typename: 'GridRecord';
                      id: string;
                      horizontalSpacing?: number | undefined;
                      verticalSpacing?: number | undefined;
                      gridContent: Array<{
                        __typename?: 'GridItemRecord';
                        xl?: number | undefined;
                        lg?: number | undefined;
                        md?: number | undefined;
                        sm?: number | undefined;
                        xs?: number | undefined;
                        imageMarginTop?: number | undefined;
                        imageMarginBottom?: number | undefined;
                        imageMarginLeft?: number | undefined;
                        imageMarginRight?: number | undefined;
                        contentMarginTop?: number | undefined;
                        contentMarginBottom?: number | undefined;
                        contentMarginLeft?: number | undefined;
                        contentMarginRight?: number | undefined;
                        hasBorder?: boolean | undefined;
                        hasBorderShadow?: boolean | undefined;
                        headingFormatting?: string | undefined;
                        paragraphFormatting?: string | undefined;
                        imageHeight?: number | undefined;
                        caseStudy?:
                          | {
                              __typename?: 'CaseStudyRecord';
                              reviewerName?: string | undefined;
                              companyPosition?: string | undefined;
                              review: string;
                              reviewerAvatar?:
                                | { __typename?: 'FileField'; url: string }
                                | undefined;
                            }
                          | undefined;
                        testimonial?:
                          | {
                              __typename?: 'TestimonialRecord';
                              reviewerName?: string | undefined;
                              companyPosition?: string | undefined;
                              review?: string | undefined;
                              reviewerAvatar?:
                                | { __typename?: 'FileField'; url: string }
                                | undefined;
                            }
                          | undefined;
                        content?:
                          | {
                              __typename?: 'GridItemModelContentField';
                              value: unknown;
                              blocks: Array<
                                | {
                                    __typename: 'CtaButtonRecord';
                                    id: string;
                                    buttonLink?: string | undefined;
                                    buttonText?: string | undefined;
                                    newTab?: boolean | undefined;
                                    trackingId?: string | undefined;
                                  }
                                | { __typename: 'ImageRecord' }
                              >;
                            }
                          | undefined;
                        contentImage?:
                          | {
                              __typename?: 'FileField';
                              url: string;
                              responsiveImage?:
                                | {
                                    __typename?: 'ResponsiveImage';
                                    aspectRatio: number;
                                    sizes: string;
                                  }
                                | undefined;
                            }
                          | undefined;
                      }>;
                    }
                  | {
                      __typename: 'LogosGroupRecord';
                      id: string;
                      logos: Array<{
                        __typename?: 'FileField';
                        url: string;
                        responsiveImage?:
                          | {
                              __typename?: 'ResponsiveImage';
                              aspectRatio: number;
                              alt?: string | undefined;
                            }
                          | undefined;
                      }>;
                    }
                  | {
                      __typename: 'ThreeByThreeTableRecord';
                      id: string;
                      threeByThreeTable: Array<{
                        __typename?: 'TextSnippetRecord';
                        image?:
                          | {
                              __typename?: 'FileField';
                              url: string;
                              alt?: string | undefined;
                              responsiveImage?:
                                | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                                | undefined;
                            }
                          | undefined;
                        content?:
                          | {
                              __typename?: 'TextSnippetModelContentField';
                              value: unknown;
                              blocks: Array<
                                | {
                                    __typename: 'CtaButtonRecord';
                                    id: string;
                                    buttonLink?: string | undefined;
                                    buttonText?: string | undefined;
                                    newTab?: boolean | undefined;
                                    trackingId?: string | undefined;
                                  }
                                | { __typename: 'ReviewerRecord' }
                              >;
                            }
                          | undefined;
                      }>;
                    }
                  | {
                      __typename: 'TwoByTwoTableRecord';
                      id: string;
                      twoByTwoContent: Array<{
                        __typename?: 'TextSnippetRecord';
                        caseStudy?:
                          | {
                              __typename?: 'CaseStudyRecord';
                              reviewerName?: string | undefined;
                              companyPosition?: string | undefined;
                              review: string;
                              reviewerAvatar?:
                                | { __typename?: 'FileField'; url: string }
                                | undefined;
                            }
                          | undefined;
                        content?:
                          | {
                              __typename?: 'TextSnippetModelContentField';
                              value: unknown;
                              blocks: Array<
                                | {
                                    __typename: 'CtaButtonRecord';
                                    id: string;
                                    buttonLink?: string | undefined;
                                    buttonText?: string | undefined;
                                    newTab?: boolean | undefined;
                                    trackingId?: string | undefined;
                                  }
                                | { __typename: 'ReviewerRecord' }
                              >;
                            }
                          | undefined;
                      }>;
                    }
                >;
              }
            | undefined;
          leftImage?:
            | {
                __typename?: 'FileField';
                url: string;
                alt?: string | undefined;
                responsiveImage?:
                  | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                  | undefined;
              }
            | undefined;
          rightImage?:
            | {
                __typename?: 'FileField';
                url: string;
                alt?: string | undefined;
                responsiveImage?:
                  | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                  | undefined;
              }
            | undefined;
          quoteBox?: { __typename?: 'ContentBlockModelQuoteBoxField'; value: unknown } | undefined;
        }>;
      }
    | undefined;
  allTeamMembers: Array<{
    __typename?: 'TeamMemberRecord';
    name: string;
    ghostId?: string | undefined;
    image: {
      __typename?: 'FileField';
      url: string;
      responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
    };
  }>;
};

export type CaseStudiesQueryVariables = Exact<{ [key: string]: never }>;

export type CaseStudiesQuery = {
  __typename?: 'Query';
  allCaseStudies: Array<{
    __typename?: 'CaseStudyRecord';
    title: string;
    reviewerName?: string | undefined;
    solution: string;
    review: string;
    project: string;
    companyUrl?: string | undefined;
    companyPosition?: string | undefined;
    date: string;
    slug?: string | undefined;
    reviewerAvatar?:
      | {
          __typename?: 'FileField';
          url: string;
          alt?: string | undefined;
          responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
        }
      | undefined;
    companyLogo?:
      | {
          __typename?: 'FileField';
          url: string;
          alt?: string | undefined;
          responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
        }
      | undefined;
  }>;
  caseStudiesPage?:
    | {
        __typename?: 'CaseStudiesPageRecord';
        title?: string | undefined;
        subtitle?: string | undefined;
        seo?:
          | {
              __typename?: 'SeoField';
              description?: string | undefined;
              title?: string | undefined;
              twitterCard?: string | undefined;
              image?: { __typename?: 'FileField'; url: string } | undefined;
            }
          | undefined;
      }
    | undefined;
  allNavitems: Array<{
    __typename?: 'NavitemRecord';
    externalUrl?: string | undefined;
    internalLink?: string | undefined;
    title?: string | undefined;
    contentPageLink?:
      | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
      | undefined;
    children: Array<{
      __typename?: 'NavitemRecord';
      externalUrl?: string | undefined;
      internalLink?: string | undefined;
      title?: string | undefined;
      contentPageLink?:
        | {
            __typename?: 'ContentPageRecord';
            slug?: string | undefined;
            title?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type CaseStudiesDetailQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type CaseStudiesDetailQuery = {
  __typename?: 'Query';
  allCaseStudies: Array<{
    __typename?: 'CaseStudyRecord';
    title: string;
    reviewerName?: string | undefined;
    solution: string;
    review: string;
    project: string;
    companyUrl?: string | undefined;
    companyPosition?: string | undefined;
    date: string;
    slug?: string | undefined;
    seo?:
      | {
          __typename?: 'SeoField';
          description?: string | undefined;
          title?: string | undefined;
          twitterCard?: string | undefined;
          image?:
            | {
                __typename?: 'FileField';
                url: string;
                responsiveImage?:
                  | {
                      __typename?: 'ResponsiveImage';
                      aspectRatio: number;
                      alt?: string | undefined;
                    }
                  | undefined;
              }
            | undefined;
        }
      | undefined;
    content?:
      | {
          __typename?: 'CaseStudyModelContentField';
          value: unknown;
          blocks: Array<
            | {
                __typename: 'ImageRecord';
                id: string;
                image?:
                  | {
                      __typename?: 'FileField';
                      url: string;
                      width?: number | undefined;
                      alt?: string | undefined;
                      height?: number | undefined;
                      responsiveImage?:
                        | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                        | undefined;
                    }
                  | undefined;
              }
            | {
                __typename: 'QuoteRecord';
                id: string;
                name?: string | undefined;
                content?:
                  | { __typename?: 'QuoteModelContentField'; blocks: Array<string>; value: unknown }
                  | undefined;
                avatar?:
                  | {
                      __typename?: 'FileField';
                      url: string;
                      width?: number | undefined;
                      alt?: string | undefined;
                      height?: number | undefined;
                      responsiveImage?:
                        | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                        | undefined;
                    }
                  | undefined;
              }
          >;
        }
      | undefined;
    reviewerAvatar?:
      | {
          __typename?: 'FileField';
          url: string;
          alt?: string | undefined;
          responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
        }
      | undefined;
    companyLogo?:
      | {
          __typename?: 'FileField';
          url: string;
          alt?: string | undefined;
          responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
        }
      | undefined;
  }>;
  allNavitems: Array<{
    __typename?: 'NavitemRecord';
    externalUrl?: string | undefined;
    internalLink?: string | undefined;
    title?: string | undefined;
    contentPageLink?:
      | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
      | undefined;
    children: Array<{
      __typename?: 'NavitemRecord';
      externalUrl?: string | undefined;
      internalLink?: string | undefined;
      title?: string | undefined;
      contentPageLink?:
        | {
            __typename?: 'ContentPageRecord';
            slug?: string | undefined;
            title?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type ContentPageQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;

export type ContentPageQuery = {
  __typename?: 'Query';
  contentPage?:
    | {
        __typename?: 'ContentPageRecord';
        slug?: string | undefined;
        seo?:
          | {
              __typename?: 'SeoField';
              description?: string | undefined;
              title?: string | undefined;
              twitterCard?: string | undefined;
              image?:
                | {
                    __typename?: 'FileField';
                    url: string;
                    responsiveImage?:
                      | {
                          __typename?: 'ResponsiveImage';
                          aspectRatio: number;
                          alt?: string | undefined;
                        }
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
        content: Array<
          | {
              __typename: 'ContactFormRecord';
              id: string;
              redirectTo?:
                | { __typename?: 'ContentPageRecord'; slug?: string | undefined }
                | undefined;
              content?: { __typename?: 'ContactFormModelContentField'; value: unknown } | undefined;
            }
          | {
              __typename: 'ContentBlockRecord';
              imageSize?: number | undefined;
              imageMargin?: number | undefined;
              content?:
                | {
                    __typename?: 'ContentBlockModelContentField';
                    value: unknown;
                    blocks: Array<
                      | {
                          __typename: 'CtaButtonRecord';
                          id: string;
                          buttonLink?: string | undefined;
                          buttonText?: string | undefined;
                          newTab?: boolean | undefined;
                          trackingId?: string | undefined;
                        }
                      | {
                          __typename: 'GridRecord';
                          id: string;
                          horizontalSpacing?: number | undefined;
                          verticalSpacing?: number | undefined;
                          gridContent: Array<{
                            __typename?: 'GridItemRecord';
                            xl?: number | undefined;
                            lg?: number | undefined;
                            md?: number | undefined;
                            sm?: number | undefined;
                            xs?: number | undefined;
                            imageMarginTop?: number | undefined;
                            imageMarginBottom?: number | undefined;
                            imageMarginLeft?: number | undefined;
                            imageMarginRight?: number | undefined;
                            contentMarginTop?: number | undefined;
                            contentMarginBottom?: number | undefined;
                            contentMarginLeft?: number | undefined;
                            contentMarginRight?: number | undefined;
                            hasBorder?: boolean | undefined;
                            hasBorderShadow?: boolean | undefined;
                            headingFormatting?: string | undefined;
                            paragraphFormatting?: string | undefined;
                            imageHeight?: number | undefined;
                            caseStudy?:
                              | {
                                  __typename?: 'CaseStudyRecord';
                                  reviewerName?: string | undefined;
                                  companyPosition?: string | undefined;
                                  review: string;
                                  reviewerAvatar?:
                                    | { __typename?: 'FileField'; url: string }
                                    | undefined;
                                }
                              | undefined;
                            testimonial?:
                              | {
                                  __typename?: 'TestimonialRecord';
                                  reviewerName?: string | undefined;
                                  companyPosition?: string | undefined;
                                  review?: string | undefined;
                                  reviewerAvatar?:
                                    | { __typename?: 'FileField'; url: string }
                                    | undefined;
                                }
                              | undefined;
                            content?:
                              | {
                                  __typename?: 'GridItemModelContentField';
                                  value: unknown;
                                  blocks: Array<
                                    | {
                                        __typename: 'CtaButtonRecord';
                                        id: string;
                                        buttonLink?: string | undefined;
                                        buttonText?: string | undefined;
                                        newTab?: boolean | undefined;
                                        trackingId?: string | undefined;
                                      }
                                    | { __typename: 'ImageRecord' }
                                  >;
                                }
                              | undefined;
                            contentImage?:
                              | {
                                  __typename?: 'FileField';
                                  url: string;
                                  responsiveImage?:
                                    | {
                                        __typename?: 'ResponsiveImage';
                                        aspectRatio: number;
                                        sizes: string;
                                      }
                                    | undefined;
                                }
                              | undefined;
                          }>;
                        }
                      | {
                          __typename: 'LogosGroupRecord';
                          id: string;
                          logos: Array<{
                            __typename?: 'FileField';
                            url: string;
                            responsiveImage?:
                              | {
                                  __typename?: 'ResponsiveImage';
                                  aspectRatio: number;
                                  alt?: string | undefined;
                                }
                              | undefined;
                          }>;
                        }
                      | {
                          __typename: 'ThreeByThreeTableRecord';
                          id: string;
                          threeByThreeTable: Array<{
                            __typename?: 'TextSnippetRecord';
                            image?:
                              | {
                                  __typename?: 'FileField';
                                  url: string;
                                  alt?: string | undefined;
                                  responsiveImage?:
                                    | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                                    | undefined;
                                }
                              | undefined;
                            content?:
                              | {
                                  __typename?: 'TextSnippetModelContentField';
                                  value: unknown;
                                  blocks: Array<
                                    | {
                                        __typename: 'CtaButtonRecord';
                                        id: string;
                                        buttonLink?: string | undefined;
                                        buttonText?: string | undefined;
                                        newTab?: boolean | undefined;
                                        trackingId?: string | undefined;
                                      }
                                    | { __typename: 'ReviewerRecord' }
                                  >;
                                }
                              | undefined;
                          }>;
                        }
                      | {
                          __typename: 'TwoByTwoTableRecord';
                          id: string;
                          twoByTwoContent: Array<{
                            __typename?: 'TextSnippetRecord';
                            caseStudy?:
                              | {
                                  __typename?: 'CaseStudyRecord';
                                  reviewerName?: string | undefined;
                                  companyPosition?: string | undefined;
                                  review: string;
                                  reviewerAvatar?:
                                    | { __typename?: 'FileField'; url: string }
                                    | undefined;
                                }
                              | undefined;
                            content?:
                              | {
                                  __typename?: 'TextSnippetModelContentField';
                                  value: unknown;
                                  blocks: Array<
                                    | {
                                        __typename: 'CtaButtonRecord';
                                        id: string;
                                        buttonLink?: string | undefined;
                                        buttonText?: string | undefined;
                                        newTab?: boolean | undefined;
                                        trackingId?: string | undefined;
                                      }
                                    | { __typename: 'ReviewerRecord' }
                                  >;
                                }
                              | undefined;
                          }>;
                        }
                    >;
                  }
                | undefined;
              leftImage?:
                | {
                    __typename?: 'FileField';
                    url: string;
                    alt?: string | undefined;
                    responsiveImage?:
                      | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                      | undefined;
                  }
                | undefined;
              rightImage?:
                | {
                    __typename?: 'FileField';
                    url: string;
                    alt?: string | undefined;
                    responsiveImage?:
                      | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                      | undefined;
                  }
                | undefined;
              quoteBox?:
                | { __typename?: 'ContentBlockModelQuoteBoxField'; value: unknown }
                | undefined;
            }
          | {
              __typename: 'HeaderRecord';
              id: string;
              content?:
                | {
                    __typename?: 'HeaderModelContentField';
                    value: unknown;
                    blocks: Array<
                      | {
                          __typename: 'CtaButtonRecord';
                          id: string;
                          buttonLink?: string | undefined;
                          buttonText?: string | undefined;
                          newTab?: boolean | undefined;
                          trackingId?: string | undefined;
                        }
                      | {
                          __typename: 'GridRecord';
                          id: string;
                          horizontalSpacing?: number | undefined;
                          verticalSpacing?: number | undefined;
                          gridContent: Array<{
                            __typename?: 'GridItemRecord';
                            xl?: number | undefined;
                            lg?: number | undefined;
                            md?: number | undefined;
                            sm?: number | undefined;
                            xs?: number | undefined;
                            imageMarginTop?: number | undefined;
                            imageMarginBottom?: number | undefined;
                            imageMarginLeft?: number | undefined;
                            imageMarginRight?: number | undefined;
                            contentMarginTop?: number | undefined;
                            contentMarginBottom?: number | undefined;
                            contentMarginLeft?: number | undefined;
                            contentMarginRight?: number | undefined;
                            hasBorder?: boolean | undefined;
                            hasBorderShadow?: boolean | undefined;
                            headingFormatting?: string | undefined;
                            paragraphFormatting?: string | undefined;
                            imageHeight?: number | undefined;
                            caseStudy?:
                              | {
                                  __typename?: 'CaseStudyRecord';
                                  reviewerName?: string | undefined;
                                  companyPosition?: string | undefined;
                                  review: string;
                                  reviewerAvatar?:
                                    | { __typename?: 'FileField'; url: string }
                                    | undefined;
                                }
                              | undefined;
                            testimonial?:
                              | {
                                  __typename?: 'TestimonialRecord';
                                  reviewerName?: string | undefined;
                                  companyPosition?: string | undefined;
                                  review?: string | undefined;
                                  reviewerAvatar?:
                                    | { __typename?: 'FileField'; url: string }
                                    | undefined;
                                }
                              | undefined;
                            content?:
                              | {
                                  __typename?: 'GridItemModelContentField';
                                  value: unknown;
                                  blocks: Array<
                                    | {
                                        __typename: 'CtaButtonRecord';
                                        id: string;
                                        buttonLink?: string | undefined;
                                        buttonText?: string | undefined;
                                        newTab?: boolean | undefined;
                                        trackingId?: string | undefined;
                                      }
                                    | { __typename: 'ImageRecord' }
                                  >;
                                }
                              | undefined;
                            contentImage?:
                              | {
                                  __typename?: 'FileField';
                                  url: string;
                                  responsiveImage?:
                                    | {
                                        __typename?: 'ResponsiveImage';
                                        aspectRatio: number;
                                        sizes: string;
                                      }
                                    | undefined;
                                }
                              | undefined;
                          }>;
                        }
                    >;
                  }
                | undefined;
              image?:
                | {
                    __typename?: 'FileField';
                    url: string;
                    responsiveImage?:
                      | {
                          __typename?: 'ResponsiveImage';
                          aspectRatio: number;
                          alt?: string | undefined;
                        }
                      | undefined;
                  }
                | undefined;
            }
        >;
      }
    | undefined;
  allNavitems: Array<{
    __typename?: 'NavitemRecord';
    externalUrl?: string | undefined;
    internalLink?: string | undefined;
    title?: string | undefined;
    contentPageLink?:
      | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
      | undefined;
    children: Array<{
      __typename?: 'NavitemRecord';
      externalUrl?: string | undefined;
      internalLink?: string | undefined;
      title?: string | undefined;
      contentPageLink?:
        | {
            __typename?: 'ContentPageRecord';
            slug?: string | undefined;
            title?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export type NavItemFragment = {
  __typename?: 'NavitemRecord';
  externalUrl?: string | undefined;
  internalLink?: string | undefined;
  title?: string | undefined;
  contentPageLink?:
    | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
    | undefined;
  children: Array<{
    __typename?: 'NavitemRecord';
    externalUrl?: string | undefined;
    internalLink?: string | undefined;
    title?: string | undefined;
    contentPageLink?:
      | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
      | undefined;
  }>;
};

export type ContentFormRecordFragment = {
  __typename: 'ContactFormRecord';
  id: string;
  redirectTo?: { __typename?: 'ContentPageRecord'; slug?: string | undefined } | undefined;
  content?: { __typename?: 'ContactFormModelContentField'; value: unknown } | undefined;
};

export type PageContentFragment = {
  __typename: 'ContentBlockRecord';
  imageSize?: number | undefined;
  imageMargin?: number | undefined;
  content?:
    | {
        __typename?: 'ContentBlockModelContentField';
        value: unknown;
        blocks: Array<
          | {
              __typename: 'CtaButtonRecord';
              id: string;
              buttonLink?: string | undefined;
              buttonText?: string | undefined;
              newTab?: boolean | undefined;
              trackingId?: string | undefined;
            }
          | {
              __typename: 'GridRecord';
              id: string;
              horizontalSpacing?: number | undefined;
              verticalSpacing?: number | undefined;
              gridContent: Array<{
                __typename?: 'GridItemRecord';
                xl?: number | undefined;
                lg?: number | undefined;
                md?: number | undefined;
                sm?: number | undefined;
                xs?: number | undefined;
                imageMarginTop?: number | undefined;
                imageMarginBottom?: number | undefined;
                imageMarginLeft?: number | undefined;
                imageMarginRight?: number | undefined;
                contentMarginTop?: number | undefined;
                contentMarginBottom?: number | undefined;
                contentMarginLeft?: number | undefined;
                contentMarginRight?: number | undefined;
                hasBorder?: boolean | undefined;
                hasBorderShadow?: boolean | undefined;
                headingFormatting?: string | undefined;
                paragraphFormatting?: string | undefined;
                imageHeight?: number | undefined;
                caseStudy?:
                  | {
                      __typename?: 'CaseStudyRecord';
                      reviewerName?: string | undefined;
                      companyPosition?: string | undefined;
                      review: string;
                      reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
                    }
                  | undefined;
                testimonial?:
                  | {
                      __typename?: 'TestimonialRecord';
                      reviewerName?: string | undefined;
                      companyPosition?: string | undefined;
                      review?: string | undefined;
                      reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
                    }
                  | undefined;
                content?:
                  | {
                      __typename?: 'GridItemModelContentField';
                      value: unknown;
                      blocks: Array<
                        | {
                            __typename: 'CtaButtonRecord';
                            id: string;
                            buttonLink?: string | undefined;
                            buttonText?: string | undefined;
                            newTab?: boolean | undefined;
                            trackingId?: string | undefined;
                          }
                        | { __typename: 'ImageRecord' }
                      >;
                    }
                  | undefined;
                contentImage?:
                  | {
                      __typename?: 'FileField';
                      url: string;
                      responsiveImage?:
                        | { __typename?: 'ResponsiveImage'; aspectRatio: number; sizes: string }
                        | undefined;
                    }
                  | undefined;
              }>;
            }
          | {
              __typename: 'LogosGroupRecord';
              id: string;
              logos: Array<{
                __typename?: 'FileField';
                url: string;
                responsiveImage?:
                  | {
                      __typename?: 'ResponsiveImage';
                      aspectRatio: number;
                      alt?: string | undefined;
                    }
                  | undefined;
              }>;
            }
          | {
              __typename: 'ThreeByThreeTableRecord';
              id: string;
              threeByThreeTable: Array<{
                __typename?: 'TextSnippetRecord';
                image?:
                  | {
                      __typename?: 'FileField';
                      url: string;
                      alt?: string | undefined;
                      responsiveImage?:
                        | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                        | undefined;
                    }
                  | undefined;
                content?:
                  | {
                      __typename?: 'TextSnippetModelContentField';
                      value: unknown;
                      blocks: Array<
                        | {
                            __typename: 'CtaButtonRecord';
                            id: string;
                            buttonLink?: string | undefined;
                            buttonText?: string | undefined;
                            newTab?: boolean | undefined;
                            trackingId?: string | undefined;
                          }
                        | { __typename: 'ReviewerRecord' }
                      >;
                    }
                  | undefined;
              }>;
            }
          | {
              __typename: 'TwoByTwoTableRecord';
              id: string;
              twoByTwoContent: Array<{
                __typename?: 'TextSnippetRecord';
                caseStudy?:
                  | {
                      __typename?: 'CaseStudyRecord';
                      reviewerName?: string | undefined;
                      companyPosition?: string | undefined;
                      review: string;
                      reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
                    }
                  | undefined;
                content?:
                  | {
                      __typename?: 'TextSnippetModelContentField';
                      value: unknown;
                      blocks: Array<
                        | {
                            __typename: 'CtaButtonRecord';
                            id: string;
                            buttonLink?: string | undefined;
                            buttonText?: string | undefined;
                            newTab?: boolean | undefined;
                            trackingId?: string | undefined;
                          }
                        | { __typename: 'ReviewerRecord' }
                      >;
                    }
                  | undefined;
              }>;
            }
        >;
      }
    | undefined;
  leftImage?:
    | {
        __typename?: 'FileField';
        url: string;
        alt?: string | undefined;
        responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
      }
    | undefined;
  rightImage?:
    | {
        __typename?: 'FileField';
        url: string;
        alt?: string | undefined;
        responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
      }
    | undefined;
  quoteBox?: { __typename?: 'ContentBlockModelQuoteBoxField'; value: unknown } | undefined;
};

export type GridRecordFragment = {
  __typename?: 'GridRecord';
  id: string;
  horizontalSpacing?: number | undefined;
  verticalSpacing?: number | undefined;
  gridContent: Array<{
    __typename?: 'GridItemRecord';
    xl?: number | undefined;
    lg?: number | undefined;
    md?: number | undefined;
    sm?: number | undefined;
    xs?: number | undefined;
    imageMarginTop?: number | undefined;
    imageMarginBottom?: number | undefined;
    imageMarginLeft?: number | undefined;
    imageMarginRight?: number | undefined;
    contentMarginTop?: number | undefined;
    contentMarginBottom?: number | undefined;
    contentMarginLeft?: number | undefined;
    contentMarginRight?: number | undefined;
    hasBorder?: boolean | undefined;
    hasBorderShadow?: boolean | undefined;
    headingFormatting?: string | undefined;
    paragraphFormatting?: string | undefined;
    imageHeight?: number | undefined;
    caseStudy?:
      | {
          __typename?: 'CaseStudyRecord';
          reviewerName?: string | undefined;
          companyPosition?: string | undefined;
          review: string;
          reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
        }
      | undefined;
    testimonial?:
      | {
          __typename?: 'TestimonialRecord';
          reviewerName?: string | undefined;
          companyPosition?: string | undefined;
          review?: string | undefined;
          reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
        }
      | undefined;
    content?:
      | {
          __typename?: 'GridItemModelContentField';
          value: unknown;
          blocks: Array<
            | {
                __typename: 'CtaButtonRecord';
                id: string;
                buttonLink?: string | undefined;
                buttonText?: string | undefined;
                newTab?: boolean | undefined;
                trackingId?: string | undefined;
              }
            | { __typename: 'ImageRecord' }
          >;
        }
      | undefined;
    contentImage?:
      | {
          __typename?: 'FileField';
          url: string;
          responsiveImage?:
            | { __typename?: 'ResponsiveImage'; aspectRatio: number; sizes: string }
            | undefined;
        }
      | undefined;
  }>;
};

export type HeaderRecordFragment = {
  __typename: 'HeaderRecord';
  id: string;
  content?:
    | {
        __typename?: 'HeaderModelContentField';
        value: unknown;
        blocks: Array<
          | {
              __typename: 'CtaButtonRecord';
              id: string;
              buttonLink?: string | undefined;
              buttonText?: string | undefined;
              newTab?: boolean | undefined;
              trackingId?: string | undefined;
            }
          | {
              __typename: 'GridRecord';
              id: string;
              horizontalSpacing?: number | undefined;
              verticalSpacing?: number | undefined;
              gridContent: Array<{
                __typename?: 'GridItemRecord';
                xl?: number | undefined;
                lg?: number | undefined;
                md?: number | undefined;
                sm?: number | undefined;
                xs?: number | undefined;
                imageMarginTop?: number | undefined;
                imageMarginBottom?: number | undefined;
                imageMarginLeft?: number | undefined;
                imageMarginRight?: number | undefined;
                contentMarginTop?: number | undefined;
                contentMarginBottom?: number | undefined;
                contentMarginLeft?: number | undefined;
                contentMarginRight?: number | undefined;
                hasBorder?: boolean | undefined;
                hasBorderShadow?: boolean | undefined;
                headingFormatting?: string | undefined;
                paragraphFormatting?: string | undefined;
                imageHeight?: number | undefined;
                caseStudy?:
                  | {
                      __typename?: 'CaseStudyRecord';
                      reviewerName?: string | undefined;
                      companyPosition?: string | undefined;
                      review: string;
                      reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
                    }
                  | undefined;
                testimonial?:
                  | {
                      __typename?: 'TestimonialRecord';
                      reviewerName?: string | undefined;
                      companyPosition?: string | undefined;
                      review?: string | undefined;
                      reviewerAvatar?: { __typename?: 'FileField'; url: string } | undefined;
                    }
                  | undefined;
                content?:
                  | {
                      __typename?: 'GridItemModelContentField';
                      value: unknown;
                      blocks: Array<
                        | {
                            __typename: 'CtaButtonRecord';
                            id: string;
                            buttonLink?: string | undefined;
                            buttonText?: string | undefined;
                            newTab?: boolean | undefined;
                            trackingId?: string | undefined;
                          }
                        | { __typename: 'ImageRecord' }
                      >;
                    }
                  | undefined;
                contentImage?:
                  | {
                      __typename?: 'FileField';
                      url: string;
                      responsiveImage?:
                        | { __typename?: 'ResponsiveImage'; aspectRatio: number; sizes: string }
                        | undefined;
                    }
                  | undefined;
              }>;
            }
        >;
      }
    | undefined;
  image?:
    | {
        __typename?: 'FileField';
        url: string;
        responsiveImage?:
          | { __typename?: 'ResponsiveImage'; aspectRatio: number; alt?: string | undefined }
          | undefined;
      }
    | undefined;
};

export type PostContentFragment = {
  __typename?: 'CaseStudyModelContentField';
  value: unknown;
  blocks: Array<
    | {
        __typename: 'ImageRecord';
        id: string;
        image?:
          | {
              __typename?: 'FileField';
              url: string;
              width?: number | undefined;
              alt?: string | undefined;
              height?: number | undefined;
              responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
            }
          | undefined;
      }
    | {
        __typename: 'QuoteRecord';
        id: string;
        name?: string | undefined;
        content?:
          | { __typename?: 'QuoteModelContentField'; blocks: Array<string>; value: unknown }
          | undefined;
        avatar?:
          | {
              __typename?: 'FileField';
              url: string;
              width?: number | undefined;
              alt?: string | undefined;
              height?: number | undefined;
              responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
            }
          | undefined;
      }
  >;
};

export type SeoFragment = {
  __typename?: 'SeoField';
  description?: string | undefined;
  title?: string | undefined;
  twitterCard?: string | undefined;
  image?:
    | {
        __typename?: 'FileField';
        url: string;
        responsiveImage?:
          | { __typename?: 'ResponsiveImage'; aspectRatio: number; alt?: string | undefined }
          | undefined;
      }
    | undefined;
};

export type TeamPageQueryVariables = Exact<{ [key: string]: never }>;

export type TeamPageQuery = {
  __typename?: 'Query';
  teamPage?:
    | {
        __typename?: 'TeamPageRecord';
        seo?:
          | {
              __typename?: 'SeoField';
              description?: string | undefined;
              title?: string | undefined;
              twitterCard?: string | undefined;
              image?:
                | {
                    __typename?: 'FileField';
                    url: string;
                    responsiveImage?:
                      | {
                          __typename?: 'ResponsiveImage';
                          aspectRatio: number;
                          alt?: string | undefined;
                        }
                      | undefined;
                  }
                | undefined;
            }
          | undefined;
        content: Array<{
          __typename: 'ContentBlockRecord';
          imageSize?: number | undefined;
          imageMargin?: number | undefined;
          content?:
            | {
                __typename?: 'ContentBlockModelContentField';
                value: unknown;
                blocks: Array<
                  | {
                      __typename: 'CtaButtonRecord';
                      id: string;
                      buttonLink?: string | undefined;
                      buttonText?: string | undefined;
                      newTab?: boolean | undefined;
                      trackingId?: string | undefined;
                    }
                  | {
                      __typename: 'GridRecord';
                      id: string;
                      horizontalSpacing?: number | undefined;
                      verticalSpacing?: number | undefined;
                      gridContent: Array<{
                        __typename?: 'GridItemRecord';
                        xl?: number | undefined;
                        lg?: number | undefined;
                        md?: number | undefined;
                        sm?: number | undefined;
                        xs?: number | undefined;
                        imageMarginTop?: number | undefined;
                        imageMarginBottom?: number | undefined;
                        imageMarginLeft?: number | undefined;
                        imageMarginRight?: number | undefined;
                        contentMarginTop?: number | undefined;
                        contentMarginBottom?: number | undefined;
                        contentMarginLeft?: number | undefined;
                        contentMarginRight?: number | undefined;
                        hasBorder?: boolean | undefined;
                        hasBorderShadow?: boolean | undefined;
                        headingFormatting?: string | undefined;
                        paragraphFormatting?: string | undefined;
                        imageHeight?: number | undefined;
                        caseStudy?:
                          | {
                              __typename?: 'CaseStudyRecord';
                              reviewerName?: string | undefined;
                              companyPosition?: string | undefined;
                              review: string;
                              reviewerAvatar?:
                                | { __typename?: 'FileField'; url: string }
                                | undefined;
                            }
                          | undefined;
                        testimonial?:
                          | {
                              __typename?: 'TestimonialRecord';
                              reviewerName?: string | undefined;
                              companyPosition?: string | undefined;
                              review?: string | undefined;
                              reviewerAvatar?:
                                | { __typename?: 'FileField'; url: string }
                                | undefined;
                            }
                          | undefined;
                        content?:
                          | {
                              __typename?: 'GridItemModelContentField';
                              value: unknown;
                              blocks: Array<
                                | {
                                    __typename: 'CtaButtonRecord';
                                    id: string;
                                    buttonLink?: string | undefined;
                                    buttonText?: string | undefined;
                                    newTab?: boolean | undefined;
                                    trackingId?: string | undefined;
                                  }
                                | { __typename: 'ImageRecord' }
                              >;
                            }
                          | undefined;
                        contentImage?:
                          | {
                              __typename?: 'FileField';
                              url: string;
                              responsiveImage?:
                                | {
                                    __typename?: 'ResponsiveImage';
                                    aspectRatio: number;
                                    sizes: string;
                                  }
                                | undefined;
                            }
                          | undefined;
                      }>;
                    }
                  | {
                      __typename: 'LogosGroupRecord';
                      id: string;
                      logos: Array<{
                        __typename?: 'FileField';
                        url: string;
                        responsiveImage?:
                          | {
                              __typename?: 'ResponsiveImage';
                              aspectRatio: number;
                              alt?: string | undefined;
                            }
                          | undefined;
                      }>;
                    }
                  | {
                      __typename: 'ThreeByThreeTableRecord';
                      id: string;
                      threeByThreeTable: Array<{
                        __typename?: 'TextSnippetRecord';
                        image?:
                          | {
                              __typename?: 'FileField';
                              url: string;
                              alt?: string | undefined;
                              responsiveImage?:
                                | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                                | undefined;
                            }
                          | undefined;
                        content?:
                          | {
                              __typename?: 'TextSnippetModelContentField';
                              value: unknown;
                              blocks: Array<
                                | {
                                    __typename: 'CtaButtonRecord';
                                    id: string;
                                    buttonLink?: string | undefined;
                                    buttonText?: string | undefined;
                                    newTab?: boolean | undefined;
                                    trackingId?: string | undefined;
                                  }
                                | { __typename: 'ReviewerRecord' }
                              >;
                            }
                          | undefined;
                      }>;
                    }
                  | {
                      __typename: 'TwoByTwoTableRecord';
                      id: string;
                      twoByTwoContent: Array<{
                        __typename?: 'TextSnippetRecord';
                        caseStudy?:
                          | {
                              __typename?: 'CaseStudyRecord';
                              reviewerName?: string | undefined;
                              companyPosition?: string | undefined;
                              review: string;
                              reviewerAvatar?:
                                | { __typename?: 'FileField'; url: string }
                                | undefined;
                            }
                          | undefined;
                        content?:
                          | {
                              __typename?: 'TextSnippetModelContentField';
                              value: unknown;
                              blocks: Array<
                                | {
                                    __typename: 'CtaButtonRecord';
                                    id: string;
                                    buttonLink?: string | undefined;
                                    buttonText?: string | undefined;
                                    newTab?: boolean | undefined;
                                    trackingId?: string | undefined;
                                  }
                                | { __typename: 'ReviewerRecord' }
                              >;
                            }
                          | undefined;
                      }>;
                    }
                >;
              }
            | undefined;
          leftImage?:
            | {
                __typename?: 'FileField';
                url: string;
                alt?: string | undefined;
                responsiveImage?:
                  | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                  | undefined;
              }
            | undefined;
          rightImage?:
            | {
                __typename?: 'FileField';
                url: string;
                alt?: string | undefined;
                responsiveImage?:
                  | { __typename?: 'ResponsiveImage'; aspectRatio: number }
                  | undefined;
              }
            | undefined;
          quoteBox?: { __typename?: 'ContentBlockModelQuoteBoxField'; value: unknown } | undefined;
        }>;
      }
    | undefined;
  allTeamMembers: Array<{
    __typename?: 'TeamMemberRecord';
    name: string;
    workPosition: string;
    appearOnFirstLine?: boolean | undefined;
    image: {
      __typename?: 'FileField';
      url: string;
      responsiveImage?: { __typename?: 'ResponsiveImage'; aspectRatio: number } | undefined;
    };
  }>;
  allNavitems: Array<{
    __typename?: 'NavitemRecord';
    externalUrl?: string | undefined;
    internalLink?: string | undefined;
    title?: string | undefined;
    contentPageLink?:
      | { __typename?: 'ContentPageRecord'; slug?: string | undefined; title?: string | undefined }
      | undefined;
    children: Array<{
      __typename?: 'NavitemRecord';
      externalUrl?: string | undefined;
      internalLink?: string | undefined;
      title?: string | undefined;
      contentPageLink?:
        | {
            __typename?: 'ContentPageRecord';
            slug?: string | undefined;
            title?: string | undefined;
          }
        | undefined;
    }>;
  }>;
};

export const NavItemFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NavItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavitemRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPageLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentPageLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<NavItemFragment, unknown>;
export const ContentFormRecordFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentFormRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFormRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redirectTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ContentFormRecordFragment, unknown>;
export const GridRecordFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GridRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'horizontalSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verticalSpacing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gridContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'xl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'md' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorderShadow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseStudy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonial' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CtaButtonRecord' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headingFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraphFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<GridRecordFragment, unknown>;
export const PageContentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageContent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CtaButtonRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TwoByTwoTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twoByTwoContent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caseStudy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerName' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'companyPosition' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerAvatar' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ThreeByThreeTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'threeByThreeTable' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'responsiveImage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'aspectRatio' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LogosGroupRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'responsiveImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'aspectRatio' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leftImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteBox' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GridRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'horizontalSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verticalSpacing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gridContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'xl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'md' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorderShadow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseStudy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonial' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CtaButtonRecord' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headingFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraphFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PageContentFragment, unknown>;
export const HeaderRecordFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeaderRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CtaButtonRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                          ]
                        }
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GridRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'horizontalSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verticalSpacing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gridContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'xl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'md' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorderShadow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseStudy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonial' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CtaButtonRecord' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headingFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraphFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<HeaderRecordFragment, unknown>;
export const PostContentFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostContent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CaseStudyModelContentField' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blocks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'QuoteRecord' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'blocks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsiveImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageRecord' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsiveImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<PostContentFragment, unknown>;
export const SeoFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Seo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitterCard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<SeoFragment, unknown>;
export const AllCaseStudySlugsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allCaseStudySlugs' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCaseStudies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AllCaseStudySlugsQuery, AllCaseStudySlugsQueryVariables>;
export const AllContentPagesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allContentPages' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allContentPages' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AllContentPagesQuery, AllContentPagesQueryVariables>;
export const AllNavitemsDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'allNavitems' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allNavitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'NullValue' }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NavItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NavItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavitemRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPageLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentPageLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<AllNavitemsQuery, AllNavitemsQueryVariables>;
export const BlogPostDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'blogPost' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blogpostCta' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'postCategory' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'StringValue', value: 'django', block: false }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageContent' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'position_ASC' }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'ghostId' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GridRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'horizontalSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verticalSpacing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gridContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'xl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'md' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorderShadow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseStudy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonial' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CtaButtonRecord' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headingFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraphFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageContent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CtaButtonRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TwoByTwoTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twoByTwoContent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caseStudy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerName' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'companyPosition' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerAvatar' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ThreeByThreeTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'threeByThreeTable' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'responsiveImage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'aspectRatio' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LogosGroupRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'responsiveImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'aspectRatio' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leftImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteBox' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<BlogPostQuery, BlogPostQueryVariables>;
export const CaseStudiesDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'caseStudies' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCaseStudies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'solution' } },
                { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewerAvatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyLogo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'caseStudiesPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'subtitle' },
                  arguments: [
                    {
                      kind: 'Argument',
                      name: { kind: 'Name', value: 'markdown' },
                      value: { kind: 'BooleanValue', value: true }
                    }
                  ]
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'description' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'twitterCard' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allNavitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'NullValue' }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NavItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NavItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavitemRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPageLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentPageLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CaseStudiesQuery, CaseStudiesQueryVariables>;
export const CaseStudiesDetailDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'caseStudiesDetail' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allCaseStudies' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'title' } },
                { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                { kind: 'Field', name: { kind: 'Name', value: 'solution' } },
                { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                { kind: 'Field', name: { kind: 'Name', value: 'project' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'date' } },
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Seo' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PostContent' } }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'reviewerAvatar' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'companyLogo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allNavitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'NullValue' }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NavItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Seo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitterCard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PostContent' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'CaseStudyModelContentField' }
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'value' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'blocks' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'QuoteRecord' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'content' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'blocks' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'value' } }
                          ]
                        }
                      },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'avatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsiveImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'InlineFragment',
                  typeCondition: {
                    kind: 'NamedType',
                    name: { kind: 'Name', value: 'ImageRecord' }
                  },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'image' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'width' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'height' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'responsiveImage' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NavItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavitemRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPageLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentPageLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<CaseStudiesDetailQuery, CaseStudiesDetailQueryVariables>;
export const ContentPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'contentPage' },
      variableDefinitions: [
        {
          kind: 'VariableDefinition',
          variable: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } },
          type: {
            kind: 'NonNullType',
            type: { kind: 'NamedType', name: { kind: 'Name', value: 'String' } }
          }
        }
      ],
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPage' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'slug' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'Variable', name: { kind: 'Name', value: 'slug' } }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Seo' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageContent' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'HeaderRecord' } },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'ContentFormRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allNavitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'NullValue' }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NavItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GridRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'horizontalSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verticalSpacing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gridContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'xl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'md' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorderShadow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseStudy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonial' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CtaButtonRecord' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headingFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraphFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Seo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitterCard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageContent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CtaButtonRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TwoByTwoTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twoByTwoContent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caseStudy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerName' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'companyPosition' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerAvatar' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ThreeByThreeTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'threeByThreeTable' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'responsiveImage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'aspectRatio' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LogosGroupRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'responsiveImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'aspectRatio' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leftImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteBox' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'HeaderRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'HeaderRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CtaButtonRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                          ]
                        }
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'ContentFormRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContactFormRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'redirectTo' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'slug' } }]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NavItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavitemRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPageLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentPageLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<ContentPageQuery, ContentPageQueryVariables>;
export const TeamPageDocument = {
  kind: 'Document',
  definitions: [
    {
      kind: 'OperationDefinition',
      operation: 'query',
      name: { kind: 'Name', value: 'teamPage' },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'teamPage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'seo' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'Seo' } }]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'PageContent' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allTeamMembers' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'orderBy' },
                value: { kind: 'EnumValue', value: 'position_ASC' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'active' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'BooleanValue', value: true }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'name' } },
                { kind: 'Field', name: { kind: 'Name', value: 'workPosition' } },
                { kind: 'Field', name: { kind: 'Name', value: 'appearOnFirstLine' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'image' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'allNavitems' },
            arguments: [
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'first' },
                value: { kind: 'IntValue', value: '500' }
              },
              {
                kind: 'Argument',
                name: { kind: 'Name', value: 'filter' },
                value: {
                  kind: 'ObjectValue',
                  fields: [
                    {
                      kind: 'ObjectField',
                      name: { kind: 'Name', value: 'parent' },
                      value: {
                        kind: 'ObjectValue',
                        fields: [
                          {
                            kind: 'ObjectField',
                            name: { kind: 'Name', value: 'eq' },
                            value: { kind: 'NullValue' }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'FragmentSpread', name: { kind: 'Name', value: 'NavItem' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'GridRecord' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'GridRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'horizontalSpacing' } },
          { kind: 'Field', name: { kind: 'Name', value: 'verticalSpacing' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'gridContent' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'xl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'lg' } },
                { kind: 'Field', name: { kind: 'Name', value: 'md' } },
                { kind: 'Field', name: { kind: 'Name', value: 'sm' } },
                { kind: 'Field', name: { kind: 'Name', value: 'xs' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginTop' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginBottom' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginLeft' } },
                { kind: 'Field', name: { kind: 'Name', value: 'contentMarginRight' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorder' } },
                { kind: 'Field', name: { kind: 'Name', value: 'hasBorderShadow' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'caseStudy' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'testimonial' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'reviewerName' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'companyPosition' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'reviewerAvatar' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [{ kind: 'Field', name: { kind: 'Name', value: 'url' } }]
                        }
                      }
                    ]
                  }
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'content' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'blocks' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                            {
                              kind: 'InlineFragment',
                              typeCondition: {
                                kind: 'NamedType',
                                name: { kind: 'Name', value: 'CtaButtonRecord' }
                              },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                                  { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                                ]
                              }
                            }
                          ]
                        }
                      }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'headingFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'paragraphFormatting' } },
                { kind: 'Field', name: { kind: 'Name', value: 'imageHeight' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'responsiveImage' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'sizes' } }
                          ]
                        }
                      },
                      { kind: 'Field', name: { kind: 'Name', value: 'url' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'Seo' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'SeoField' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'description' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          { kind: 'Field', name: { kind: 'Name', value: 'twitterCard' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'image' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'PageContent' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'ContentBlockRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageSize' } },
          { kind: 'Field', name: { kind: 'Name', value: 'imageMargin' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'content' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'blocks' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: '__typename' } },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'CtaButtonRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonLink' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'buttonText' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'newTab' } },
                            { kind: 'Field', name: { kind: 'Name', value: 'trackingId' } }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'TwoByTwoTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'twoByTwoContent' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'caseStudy' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerName' }
                                        },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'companyPosition' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'review' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'reviewerAvatar' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'url' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'ThreeByThreeTableRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'threeByThreeTable' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'image' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'responsiveImage' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: 'aspectRatio' }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'content' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        { kind: 'Field', name: { kind: 'Name', value: 'value' } },
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'blocks' },
                                          selectionSet: {
                                            kind: 'SelectionSet',
                                            selections: [
                                              {
                                                kind: 'Field',
                                                name: { kind: 'Name', value: '__typename' }
                                              },
                                              {
                                                kind: 'InlineFragment',
                                                typeCondition: {
                                                  kind: 'NamedType',
                                                  name: { kind: 'Name', value: 'CtaButtonRecord' }
                                                },
                                                selectionSet: {
                                                  kind: 'SelectionSet',
                                                  selections: [
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'id' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonLink' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'buttonText' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'newTab' }
                                                    },
                                                    {
                                                      kind: 'Field',
                                                      name: { kind: 'Name', value: 'trackingId' }
                                                    }
                                                  ]
                                                }
                                              }
                                            ]
                                          }
                                        }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      {
                        kind: 'InlineFragment',
                        typeCondition: {
                          kind: 'NamedType',
                          name: { kind: 'Name', value: 'LogosGroupRecord' }
                        },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'logos' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'responsiveImage' },
                                    selectionSet: {
                                      kind: 'SelectionSet',
                                      selections: [
                                        {
                                          kind: 'Field',
                                          name: { kind: 'Name', value: 'aspectRatio' }
                                        },
                                        { kind: 'Field', name: { kind: 'Name', value: 'alt' } }
                                      ]
                                    }
                                  }
                                ]
                              }
                            }
                          ]
                        }
                      },
                      { kind: 'FragmentSpread', name: { kind: 'Name', value: 'GridRecord' } }
                    ]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'leftImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'rightImage' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'url' } },
                { kind: 'Field', name: { kind: 'Name', value: 'alt' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'responsiveImage' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [{ kind: 'Field', name: { kind: 'Name', value: 'aspectRatio' } }]
                  }
                }
              ]
            }
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'quoteBox' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [{ kind: 'Field', name: { kind: 'Name', value: 'value' } }]
            }
          }
        ]
      }
    },
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NavItem' },
      typeCondition: { kind: 'NamedType', name: { kind: 'Name', value: 'NavitemRecord' } },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'contentPageLink' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          },
          { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
          { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
          { kind: 'Field', name: { kind: 'Name', value: 'title' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'children' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'contentPageLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'slug' } },
                      { kind: 'Field', name: { kind: 'Name', value: 'title' } }
                    ]
                  }
                },
                { kind: 'Field', name: { kind: 'Name', value: 'externalUrl' } },
                { kind: 'Field', name: { kind: 'Name', value: 'internalLink' } },
                { kind: 'Field', name: { kind: 'Name', value: 'title' } }
              ]
            }
          }
        ]
      }
    }
  ]
} as unknown as DocumentNode<TeamPageQuery, TeamPageQueryVariables>;
